<template>
  <TableTemplate :title="$t('options.statusRate')" transition-speed="0.7s">
    <details-link href="https://astronova.io/page29773654.html" target="_blank" class="details-link" />
    <h5 class="cosmogram-title">{{ $t('cosmogramm') }}</h5>
    <table :class="{ 'ph-loading--table': showSkeleton }">
      <thead v-if="isLoaded">
        <tr>
          <th>
            <span>{{ $t('commonRate') }}</span>
          </th>
          <th>
            <span>{{ data.ascInfo.name }}</span>
          </th>
          <th>
            <img :src="require(`@/assets/planets/${data.sunInfo.name}.svg`)" />
          </th>
          <th>
            <img :src="require(`@/assets/planets/${data.moonInfo.name}.svg`)" />
          </th>
        </tr>
      </thead>
      <tbody v-if="isLoaded">
        <tr>
          <table-cell rowspan="2" :val="{ mark: data.overallMark }" />
          <td>
            <span>{{ $t('connect') }}</span>
          </td>
          <td class="connection-cell">
            <img v-for="name in data.sunInfo.connection" :key="name" :src="require(`@/assets/planets/${name}.svg`)" />
          </td>
          <td class="connection-cell">
            <img v-for="name in data.moonInfo.connection" :key="name" :src="require(`@/assets/planets/${name}.svg`)" />
          </td>
        </tr>
        <tr>
          <td>
            <span>{{ $t('sign') }}</span>
          </td>
          <td class="sign-cell">
            <img :src="require(`@/assets/zodiacs/${data.sunInfo.sign}.svg`)" />
          </td>
          <td class="sign-cell">
            <img :src="require(`@/assets/zodiacs/${data.moonInfo.sign}.svg`)" />
          </td>
        </tr>
        <!-- end of top table part -->
        <tr>
          <table-cell rowspan="6" :val="data.stabilityMarks[0]" />
          <table-cell rowspan="3" :val="data.rationalMarks[0]" />
          <table-cell rowspan="3" :val="data.elementalMarks[0]" />
          <table-cell :val="data.planetMarks[0]" />
        </tr>
        <tr>
          <table-cell :val="data.planetMarks[1]" />
        </tr>
        <tr>
          <table-cell :val="data.planetMarks[2]" />
        </tr>
        <!-- end of first line for rational/elemental marks -->
        <tr>
          <table-cell rowspan="3" :val="data.rationalMarks[1]" />
          <table-cell rowspan="3" :val="data.elementalMarks[1]" />
          <table-cell :val="data.planetMarks[3]" />
        </tr>
        <tr>
          <table-cell :val="data.planetMarks[4]" />
        </tr>
        <tr>
          <table-cell :val="data.planetMarks[5]" />
        </tr>
        <!-- end of second line for rational/elemental marks -->
        <tr>
          <table-cell rowspan="6" :val="data.stabilityMarks[1]" />
          <table-cell rowspan="3" :val="data.rationalMarks[2]" />
          <table-cell rowspan="3" :val="data.elementalMarks[2]" />
          <table-cell :val="data.planetMarks[6]" />
        </tr>
        <tr>
          <table-cell :val="data.planetMarks[7]" />
        </tr>
        <tr>
          <table-cell :val="data.planetMarks[8]" />
        </tr>
        <!-- end of third line for rational/elemental marks -->
        <tr>
          <table-cell rowspan="3" :val="data.rationalMarks[3]" />
          <table-cell rowspan="3" :val="data.elementalMarks[3]" />
          <table-cell :val="data.planetMarks[9]" />
        </tr>
        <tr>
          <table-cell :val="data.planetMarks[10]" />
        </tr>
        <tr>
          <table-cell :val="data.planetMarks[11]" />
        </tr>
      </tbody>
    </table>
  </TableTemplate>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import TableTemplate from '../TableTemplate';
import TableCell from './TableCell';
import DetailsLink from '../DetailsLink';

export default {
  name: 'CosmogramStatus',
  components: {
    TableTemplate,
    TableCell,
    DetailsLink,
  },
  computed: {
    ...mapState('ChartModule', {
      data: 'statusAssessment',
    }),
    ...mapGetters('ChartModule', ['showSkeleton']),
    isLoaded() {
      return this.data && this.data.ascInfo && this.data.sunInfo;
    },
  },
};
</script>

<style lang="sass" scoped>
@import "src/style/application"

.cosmogram-title
  color: grey-color('500')
  text-align: center
  font-size: base-unit(18)
  font-weight: 400
  margin-bottom: base-unit(15)

.details-link
  margin-bottom: base-unit(10)

table
  width: 100%
  border-collapse: collapse
  border-style: hidden
  table-layout: fixed
  td
    border: base-unit(1) solid #EFEFEF
    padding: base-unit(4) base-unit(8)
    color: grey-color('500')
    font-size: base-unit(16)
    text-align: center
  img
    vertical-align: middle

tr
  th
    color: grey-color('500')
    font-weight: 400
    text-align: center
    font-size: base-unit(16)
    padding: base-unit(4) base-unit(8)

.sign-cell
  img
    width: base-unit(15)
    height: base-unit(15)

.connection-cell
  img:not(:last-child)
    margin-right: base-unit(5)
</style>
