export const convertToDms = (decimalDegree) => {
  const sign = decimalDegree < 0 ? '-' : '';
  const absDd = Math.abs(decimalDegree);
  const deg = absDd | 0;
  const frac = absDd - deg;
  const min = (frac * 60) | 0;
  const sec = Math.round(frac * 3600 - min * 60);

  return `${sign}${deg}°${min}'${sec}"`;
};
