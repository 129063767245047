<template>
  <v-tooltip :value="show" :position-x="positionX" :position-y="positionY" content-class="tooltip-white" right>
    <component :is="viewComponent" :data="data"></component>
  </v-tooltip>
</template>

<script>
import { TooltipType } from 'astro-chart';
import debounce from 'lodash/debounce';

import { sendMetrics } from '@/utils/metrics/metrics';
import { AspectView, CuspView, PlanetView, ZodiacView } from './views';

export default {
  name: 'ChartTooltip',
  props: {
    data: {
      required: true,
    },
    active: {
      required: true,
    },
  },
  data() {
    return {
      viewComponent: null,
      components: {
        [TooltipType.ASPECT]: AspectView,
        [TooltipType.PLANET]: PlanetView,
        [TooltipType.ZODIAC]: ZodiacView,
        [TooltipType.CUSP]: CuspView,
      },
      positionX: 0,
      positionY: 0,
      show: false,
    };
  },
  watch: {
    active(newVal) {
      this.setShow(newVal);
    },
    data: {
      deep: true,
      handler() {
        if (!this.data) {
          return;
        }

        const { type, nativeEvent } = this.data;

        this.viewComponent = this.components[type];

        const position = nativeEvent.touches ? nativeEvent.touches[0] : nativeEvent;
        const positionOffset = 5;

        this.positionX = position.clientX + positionOffset;
        this.positionY = position.clientY + positionOffset;

        sendMetrics(`tooltip_${type}`);
      },
    },
  },
  methods: {
    setShow: debounce(function (value) {
      this.show = value;
    }, 100),
  },
};
</script>

<style scoped lang="sass"></style>
