<template>
  <div class="table-template">
    <div :class="['table-template__header', { blocked }]" tabindex="0" @click="toggle">
      <div class="table-template__header-title" :class="{ 'ph-loading--title': showSkeleton }">
        <span>{{ title }}</span>
        <v-tooltip v-if="beta" right content-class="tooltip-white">
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on" class="beta-sign">Beta</span>
          </template>
          {{ $t('beta') }}
        </v-tooltip>
        <v-tooltip v-if="blocked" right content-class="tooltip-white">
          <template v-slot:activator="{ on, attrs }">
            <img
              v-bind="attrs"
              v-on="on"
              class="lock"
              v-if="blocked && blockedForUnregistered"
              :src="require('@/assets/icons/lock-blue.svg')"
            />
            <img
              v-bind="attrs"
              v-on="on"
              class="lock"
              v-if="temporarilyBlocked"
              :src="require('@/assets/icons/question.svg')"
            />
          </template>
          <template v-if="blockedForUnregistered">
            {{ $t('registered') }}
          </template>
          <template v-else-if="temporarilyBlocked">
            {{ $t('unavailable') }}
          </template>
          <template v-else>
            {{ $t('forPremium') }}
          </template>
        </v-tooltip>
      </div>
      <button class="table-template__header-toggle" v-if="!blocked">
        <img :src="require('@/assets/icons/arrow-down-blue.svg')" :class="{ rotate: isExpanded }" />
      </button>
    </div>
    <collapse-transition :transition-speed="transitionSpeed">
      <div
        :class="['table-template__body', { hidden: alwaysDrawContent && !isExpanded }]"
        v-show="isExpanded || alwaysDrawContent"
      >
        <slot></slot>
      </div>
    </collapse-transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { CollapseTransition } from '@/elements/components/transitions';

export default {
  name: 'TableTemplate',
  components: {
    CollapseTransition,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    // if true content is always drawn but hidden by the screen edge
    alwaysDrawContent: {
      type: Boolean,
      default: false,
    },
    expandByDefault: {
      type: Boolean,
      default: false,
    },
    beta: {
      type: Boolean,
      default: false,
    },
    blocked: {
      type: Boolean,
      default: false,
    },
    blockedForUnregistered: {
      default: false,
      type: Boolean,
    },
    temporarilyBlocked: {
      default: false,
      type: Boolean,
    },
    transitionSpeed: {
      type: String,
      default: '0.3s',
    },
  },
  data() {
    return {
      isExpanded: this.expandByDefault,
    };
  },
  computed: {
    ...mapGetters('ChartModule', ['showSkeleton']),
  },
  watch: {
    expandByDefault(value) {
      this.isExpanded = value;
    },
    blocked(value) {
      if (value) this.isExpanded = false;
    },
  },
  methods: {
    toggle() {
      if (!this.blocked) {
        this.isExpanded = !this.isExpanded;
        this.$emit('toggle', this.isExpanded);
      }
    },
  },
};
</script>

<style scoped lang="sass">
.table-template
  display: flex
  flex-direction: column
  padding: base-unit(20) 0
  box-sizing: border-box
  background-color: white
  border-bottom: base-unit(1) solid grey-color('10')

  &__header
    display: flex
    justify-content: space-between
    align-items: center
    cursor: pointer
    pointer-events: all
    +base-font-size
    font-weight: 500
    color: blue-color('primary')
    &:hover
      color: black-color('secondary')
    &.blocked
      color: grey-color('400')
      cursor: default
      &:hover
        color: grey-color('400')

    &-title
      display: flex
      align-items: center

    &-toggle
      width: base-unit(36)
      height: base-unit(36)
      border-radius: 50%
      background: #EDF4F7
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.075)
      display: flex
      justify-content: center
      align-items: center
      +large-devices
        background: transparent
        box-shadow: none
        height: base-unit(13)
        width: base-unit(11)
      img
        height: base-unit(13)
        width: base-unit(11)
        transition: all 0.3s linear
        +large-devices
          width: 100%
          height: 100%
        &.rotate
          transform: rotate(180deg)


  &__body
    margin-top: base-unit(16)
    opacity: 1
    height: auto

// TODO: find a way to fix animation
.hidden
  position: fixed
  top: -1000px

.beta-sign
  color: grey-color('500')
  margin-left: base-unit(10)
  font-weight: 200
  +medium-devices
    font-size: base-unit(16)

.lock
  margin-left: base-unit(10)
</style>
