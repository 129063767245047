<template>
  <action-view v-on="$listeners" class="swap-button">
    <img :src="require('@/assets/icons/swap.svg')" width="16" height="16" alt="" />
  </action-view>
</template>

<script>
import ActionView from '@/elements/atoms/ActionView';

export default {
  name: 'SwapButton',
  components: {
    ActionView,
  },
};
</script>

<style scoped lang="sass">
.swap-button
  padding: base-unit(8)
  display: flex
  align-items: center
</style>
