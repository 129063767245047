<template>
  <table-template :expandByDefault="true" :title="$t('chartPage.fastEdit')">
    <date-time-actions v-if="!isMobileScreen" />
    <div class="params-container">
      <template v-if="!showTwoBirthPlaces">
        <div v-if="firstPersonBirthPlaceData.birthPlace" class="params-container__item">
          <page-subtitle :class="{ 'ph-loading--title': showSkeleton }">
            {{ $t('createMap.birthPlace') }}
          </page-subtitle>
          <SearchBirthPlace
            :form="firstPersonBirthPlaceData"
            hide-place-types-buttons
            @change="(place) => setPersonBirthPlace({ place, personKey: 'firstPersonData' })"
            :class="{ 'ph-loading--content': showSkeleton }"
          />
        </div>
        <div v-if="!isDoubleChart" class="params-container__item">
          <page-subtitle :class="{ 'ph-loading--title': showSkeleton }">
            {{ $t('pageSettings.houseSystem') }}
          </page-subtitle>
          <astro-select
            hide-details
            :items="houseSystemItems"
            v-model="chartHouseSystem"
            class="map-types-select"
            :class="{ 'ph-loading--content': showSkeleton }"
          />
        </div>
      </template>
      <template v-if="showTwoBirthPlaces">
        <div v-if="firstPersonBirthPlaceData.birthPlace" class="params-container__item">
          <page-subtitle :class="{ 'ph-loading--title': showSkeleton }">
            {{ $t('createMap.birthPlace') }} 1
          </page-subtitle>
          <SearchBirthPlace
            :form="firstPersonBirthPlaceData"
            hide-place-types-buttons
            @change="(place) => setPersonBirthPlace({ place, personKey: 'firstPersonData' })"
            :class="{ 'ph-loading--content': showSkeleton }"
          />
        </div>
        <div v-if="secondPersonBirthPlaceData.birthPlace" class="params-container__item">
          <page-subtitle :class="{ 'ph-loading--title': showSkeleton }">
            {{ $t('createMap.birthPlace') }} 2
          </page-subtitle>
          <SearchBirthPlace
            :form="secondPersonBirthPlaceData"
            hide-place-types-buttons
            @change="(place) => setPersonBirthPlace({ place, personKey: 'secondPersonData' })"
            :class="{ 'ph-loading--content': showSkeleton }"
          />
        </div>
      </template>
    </div>
    <!--    <page-subtitle :class="{ 'ph-loading&#45;&#45;title': showSkeleton }">
      Коррекция орбисов
    </page-subtitle>
    <date-form-orbs-slider />-->
  </table-template>
</template>
<script>
import { mapGetters, mapActions, mapState } from 'vuex';

import { PageSubtitle } from '@/elements/components/page';
import TableTemplate from '../tables/TableTemplate';
import { DateTimeActions, DateFormOrbsSlider } from './index.js';
import { ChartTypeTranslation, HOUSE_SYSTEM_TYPE } from '@/types';
import AstroSelect from '@/elements/components/AstroSelect';
import SearchBirthPlace from '@/elements/pages/natal-map/SearchBirthPlace';
import { ChartType } from 'astro-chart';
import { i18n } from '@/plugins/i18n';

export default {
  name: 'DateFormAction',
  components: {
    TableTemplate,
    AstroSelect,
    PageSubtitle,
    DateTimeActions,
    SearchBirthPlace,
    DateFormOrbsSlider,
  },
  data() {
    return {
      /*chartTypes: Object.keys(ChartTypeTranslation).map((key) => ({
        text: ChartTypeTranslation[key],
        value: key,
      })),
      houseSystemItems: Object.keys(HOUSE_SYSTEM_TRANSLATION).map((key) => ({
        text: HOUSE_SYSTEM_TRANSLATION[key],
        value: key,
      })),*/
    };
  },
  computed: {
    ...mapState('ChartModule', ['requestParams']),
    ...mapState('UserModule', ['settings']),
    ...mapGetters('ChartModule', ['showSkeleton', 'isDoubleChart', 'houseSystem']),
    showTwoBirthPlaces() {
      const { chartType } = this.requestParams;
      return this.isDoubleChart && chartType !== ChartType.TRANSIT;
    },
    firstPersonBirthPlaceData() {
      return {
        birthPlaceType: 'name',
        birthPlace: this.requestParams.firstPersonData && this.requestParams.firstPersonData.place,
      };
    },
    secondPersonBirthPlaceData() {
      return {
        birthPlaceType: 'name',
        birthPlace: this.requestParams.secondPersonData && this.requestParams.secondPersonData.place,
      };
    },
    chartTypes() {
      return Object.keys(ChartTypeTranslation).map((key) => ({
        text: i18n.t(ChartTypeTranslation[key]),
        value: key,
      }));
    },
    houseSystemItems() {
      return Object.keys(HOUSE_SYSTEM_TYPE).map((key) => ({
        text: i18n.t(HOUSE_SYSTEM_TYPE[key]),
        value: key,
      }));
    },

    chartHouseSystem: {
      get() {
        return this.houseSystem;
      },
      async set(value) {
        await this.setHouseSystem(value);
      },
    },
  },
  methods: {
    ...mapActions('ChartModule', ['setHouseSystem', 'setPersonBirthPlace']),
  },

  beforeMount() {
    this.houseSystemItems.unshift({
      text: 'Auto',
      value: 'auto',
    });
  },
};
</script>
<style scoped lang="sass">
.params-container
  display: grid
  grid-template-columns: 1fr
  grid-template-rows: 1fr 1fr
  gap: base-unit(16)
  margin-top: base-unit(20)
  margin-bottom: base-unit(20)

  +small-devices
    grid-template-columns: 1fr 1fr
    grid-template-rows: 1fr

  &__item
    ::v-deep .v-input__slot
      margin-bottom: 0

    ::v-deep .v-text-field__details
      display: none

.slider
  margin-top: base-unit(15)

  ::v-deep .v-slider__thumb-label
    background: transparent !important
    color: grey-color('500')
</style>
