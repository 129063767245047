<template>
  <transition name="prompt">
    <div ref="prompt" v-if="active" :style="positionStyle" class="chart-prompt">
      <component :is="viewComponent" :data="data"></component>
      <cross-button color="grey" class="chart-prompt__cross-btn" @click="onClose" />
    </div>
  </transition>
</template>

<script>
import debounce from 'lodash/debounce';
import CrossButton from '@/elements/components/CrossButton';
import { TooltipType } from 'astro-chart';
import { AspectView, CuspView, PlanetView, ZodiacView } from './views';

export default {
  name: 'ChartPrompt',
  components: { CrossButton },
  props: {
    data: {
      required: true,
    },
    active: {
      required: true,
    },
  },
  data() {
    return {
      viewComponent: null,
      positionStyle: null,
      components: {
        [TooltipType.ASPECT]: AspectView,
        [TooltipType.PLANET]: PlanetView,
        [TooltipType.ZODIAC]: ZodiacView,
        [TooltipType.CUSP]: CuspView,
      },
    };
  },
  watch: {
    data: {
      deep: true,
      handler() {
        if (!this.data) {
          return;
        }

        const { type, nativeEvent } = this.data;
        this.viewComponent = this.components[type];

        const position = nativeEvent.touches ? nativeEvent.touches[0] : nativeEvent;
        const offsetX = 10;
        const offsetY = 40;
        this.positionX = position.clientX - offsetX;
        this.positionY = position.clientY - offsetY;

        const top = `${position.clientY - offsetY}px`;
        const left = `${position.clientX - offsetX}px`;
        const right = `${window.innerWidth - position.clientX - offsetX}px`;

        this.positionStyle = this.positionX < window.innerWidth / 2 ? { top, left } : { top, right };

        this.setClose();
      },
    },
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    setClose: debounce(function () {
      this.onClose();
    }, 3000),
  },
};
</script>

<style scoped lang="sass">
.chart-prompt
  position: absolute
  z-index: 100
  display: flex
  align-items: center
  width: fit-content
  max-width: base-unit(290)
  padding: base-unit(6) base-unit(10)
  font-size: base-unit(12)
  color: grey-color('500')
  line-height: 1.5
  border: 1px solid grey-color('10')
  box-sizing: border-box
  background-color: white
  border-radius: base-unit(5)
  overflow: visible
  +astro-box-shadow

  &__cross-btn
    width: auto
    height: auto
    margin-left: base-unit(6)

.prompt-enter-active, .prompt-leave-active
  transition: opacity 0.5s

.prompt-enter, .prompt-leave-to
  opacity: 0
</style>
