<template>
  <div class="meta-item" v-if="data">
    <span class="meta-item__title">{{ $t(metaTitle) }}:</span>
    <span>
      {{ data.geo.placeName }} / {{ mapTime }},
      <!--      {{ this.data.time }}-->
      {{ timeShift }} / {{ data.geo.latitudeInDegrees }}, {{ data.geo.longitudeInDegrees }} /
      {{ $t(mapType) }}
      {{ buildTime }}
    </span>
    <div v-if="data.geo.latitudeInDegrees.slice(0, 2) > 62 && data.houseSystemConfig.auto" class="meta-item__north">
      <span class="meta-item__title">
        {{ $t('metaTooltip1') }}
      </span>
      <help-tooltip>
        {{ $t('metaTooltip2') }}
      </help-tooltip>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import HelpTooltip from '@/elements/components/HelpTooltip';

export default {
  name: 'MetaItem',
  components: { HelpTooltip },
  props: {
    data: {
      type: Object,
      required: true,
    },
    doubleMapDetails: {
      type: Object,
      default: () => ({}),
    },
    metaTitle: {
      type: String,
      required: true,
    },
  },
  computed: {
    mapTime() {
      if (this.$i18n.locale === 'en') {
        moment.locale('en');
        return moment(new Date(this.data.metaDate), 'DD-MM-YYYY', 'en', true);
      } else {
        moment.locale('de');
        return moment(new Date(this.data.metaDate), 'DD-MM-YYYY', 'de', true);
      }
      /* let date = new Date(this.data.date);
      return moment(date, ['LL'], 'de', true);*/
    },

    timeShift() {
      return this.data.dateShift > 0 ? `+${this.data.gmtInfo.gmtFormatted}` : this.data.gmtInfo.gmtFormatted;
    },
    mapType() {
      return this.data.houseSystemConfig?.houseSystem;
    },
    buildTime() {
      if (this.doubleMapDetails) {
        if (this.doubleMapDetails.solarDatetime) {
          const date = moment(this.doubleMapDetails.solarDatetime);

          return `(${this.$i18n.t('SOLAR')}: ${date.format('DD.MM.YYYY')} in ${date.format('HH:mm:ss')})`;
        }
        if (this.doubleMapDetails.progressionDatetime) {
          const date = moment(this.doubleMapDetails.progressionDatetime);

          return `(${this.$i18n.t('PROGRESSION')}: ${date.format('DD.MM.YYYY')} in ${date.format('HH:mm:ss')})`;
        }
        if (this.doubleMapDetails.directionShift) {
          return `(${this.$i18n.t('DIRECTION')}: смещение на ${this.doubleMapDetails.directionShift}°)`;
        }
        return '';
      } else {
        return '';
      }
    },
  },
};
</script>

<style scoped lang="sass">
.meta-item
  padding: base-unit(4) 0
  white-space: break-spaces
  font-size: base-unit(12)
  font-weight: 400
  text-align: center
  color: grey-color('500')
  +medium-devices
    font-size: base-unit(14)

  &__title
    color: grey-color('400')

  &__north
    display: flex
    align-items: center
    margin-top: base-unit(10)
    min-width: base-unit(280)
</style>
