<template>
  <chart
    :chart-tooltip="chartTooltip"
    :chart-prompt="chartPrompt"
    :chart-id="elementId"
    :show-slider="isZoomEnabled"
    @close-chart-prompt="onChartPromptClose"
  >
    <template v-slot:meta>
      <meta-container :class="{ 'ph-loading--content': showSkeleton }">
        <span class="chart-name">{{ mapName }}</span>
        <meta-item v-if="meta && meta.firstPersonData" :data="meta.firstPersonData" :metaTitle="$t('natalMap')" />
      </meta-container>
    </template>
  </chart>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import chartMixin from '../chart-mixin';
import natalSettings from './settings';
import { enrichPlanetsWithOrbs, transformCuspListToCuspDto } from '../utils';
import Chart from '../Chart';
import { MetaContainer, MetaItem } from '../components/meta';

export default {
  name: 'NatalChart',
  mixins: [chartMixin],
  components: {
    Chart,
    MetaContainer,
    MetaItem,
  },
  data() {
    return {
      elementId: 'natal-chart',
      size: 500,
      chartSettings: natalSettings,
    };
  },
  computed: {
    ...mapState('ChartModule', ['firstPersonData']),
    ...mapGetters('ChartModule', ['currentOrbsConfig', 'showSkeleton', 'mapName']),
    chartData() {
      const { planets, cusps } = this.firstPersonData.params;

      return {
        planets: enrichPlanetsWithOrbs(planets, this.currentOrbsConfig),
        cusps: transformCuspListToCuspDto(cusps),
      };
    },
  },
};
</script>

<style lang="sass" scoped>
.chart-name
  font-size: base-unit(14)
  font-weight: 500
  color: grey-color('500')
</style>
