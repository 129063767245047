<template>
  <!-- eslint-disable max-len -->
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 740 740"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style="display: block"
  >
    <path
      d="M307.185 5.37105C371.799 -5.76001 438.21 0.478324 499.62 23.4475L487.265 56.4807C431.708 35.701 371.627 30.0573 313.172 40.1274L307.185 5.37105Z"
      fill="#FFEEF0"
    />
    <path
      d="M498.561 23.0533C560.994 46.1876 616.027 85.7398 657.857 137.539L630.419 159.697C592.575 112.835 542.788 77.0533 486.307 56.1242L498.561 23.0533Z"
      fill="#D0E8FC"
    />
    <path
      d="M655.314 134.425C697.057 184.98 724.616 245.719 735.172 310.425L700.364 316.104C690.814 257.566 665.882 202.616 628.118 156.88L655.314 134.425Z"
      fill="#DFF7FA"
    />
    <path
      d="M734.741 307.842C745.891 373.269 739.237 440.491 715.476 502.462L682.545 489.836C704.042 433.772 710.062 372.957 699.974 313.767L734.741 307.842Z"
      fill="#DCF4E7"
    />
    <path
      d="M716.635 499.398C692.665 563.611 651.329 619.904 597.237 661.999L575.577 634.165C624.513 596.083 661.909 545.156 683.594 487.064L716.635 499.398Z"
      fill="#FFE9EB"
    />
    <path
      d="M605.676 655.231C556.004 696.273 496.476 723.632 432.982 734.6L426.979 699.847C484.42 689.924 538.274 665.173 583.211 628.043L605.676 655.231Z"
      fill="#D0E8FC"
    />
    <path
      d="M435.012 734.244C365.258 746.694 293.396 738.853 227.968 711.653L241.506 679.087C300.698 703.694 365.71 710.787 428.815 699.524L435.012 734.244Z"
      fill="#DFF7FA"
    />
    <path
      d="M241.672 717.033C181.238 694.685 127.687 656.943 86.3201 607.541L113.36 584.899C150.784 629.591 199.23 663.736 253.904 683.954L241.672 717.033Z"
      fill="#DCF4E7"
    />
    <path
      d="M86.6822 607.973C42.326 555.165 13.6116 491.01 3.77949 422.75L38.6875 417.722C47.5825 479.476 73.5597 537.515 113.688 585.289L86.6822 607.973Z"
      fill="#FFE9EB"
    />
    <path
      d="M5.14095 431.464C-5.56282 367.926 0.51179 302.693 22.7635 242.224L55.862 254.403C35.7313 309.109 30.2357 368.123 39.9192 425.606L5.14095 431.464Z"
      fill="#D0E8FC"
    />
    <path
      d="M22.7603 242.232C46.0589 178.913 86.2279 123.169 138.922 81.0313L160.948 108.576C113.277 146.697 76.9369 197.127 55.859 254.411L22.7603 242.232Z"
      fill="#DFF7FA"
    />
    <path
      d="M133.448 85.495C183.356 43.9992 243.301 16.3617 307.266 5.35704L313.246 40.1147C255.378 50.0704 201.146 75.0735 155.996 112.614L133.448 85.495Z"
      fill="#DCF4E7"
    />
  </svg>
</template>
<script>
export default {
  name: 'ChartPlaceholder',
};
</script>
<style lang="sass" scoped>
svg
  padding: base-unit(35)
</style>
