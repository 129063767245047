<template>
  <tooltip-view :name="name" :description="content" />
</template>

<script>
import TooltipView from './BaseView';
import { ZODIAC_ELEMENT_TRANSLATION, ZODIAC_NAMES_TRANSLATION } from '@/types';

export default {
  name: 'ZodiacView',
  components: { TooltipView },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    name() {
      return ZODIAC_NAMES_TRANSLATION[this.data.name];
    },
    content() {
      return ZODIAC_ELEMENT_TRANSLATION[this.data.element];
    },
  },
};
</script>

<style scoped lang="sass"></style>
