<template>
  <div class="meta-container">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'MetaContainer',
};
</script>

<style scoped lang="sass">
.meta-container
  margin: base-unit(5) base-unit(20) base-unit(20)
  box-sizing: border-box
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  +medium-devices
    margin: base-unit(20)
    margin-bottom: 0
  &.ph-loading--content
    min-width: base-unit(200)
    +medium-devices
      min-width: base-unit(500)
</style>
