<template>
  <td>
    <div class="couple">
      <v-tooltip v-if="val.hint" right content-class="tooltip-white">
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on">
            <div>{{ val.mark.value }}</div>
            <div>{{ val.mark.index }}</div>
          </div>
        </template>
        {{ $t(hint) }}
      </v-tooltip>
      <template v-else>
        <div>{{ val.mark.value }}</div>
        <div>{{ val.mark.index }}</div>
      </template>
    </div>
  </td>
</template>

<script>
export default {
  name: 'TableCell',
  props: {
    val: {
      type: Object,
      requied: true,
    },
  },
  computed: {
    hint() {
      let hintTip;
      switch (this.val.hint) {
        case 'Огонь':
          hintTip = 'spirits.fire';
          break;
        case 'Земля':
          hintTip = 'spirits.earth';
          break;
        case 'Вода':
          hintTip = 'spirits.water';
          break;
        case 'Воздух':
          hintTip = 'spirits.air';
          break;
        case 'Эмоциональный':
          hintTip = 'emotional';
          break;
        case 'Рациональный':
          hintTip = 'emotional';
          break;
        case 'Ян':
          hintTip = 'yang';
          break;
        case 'Инь':
          hintTip = 'yin';
          break;
        case 'Овен':
          hintTip = 'ARIES';
          break;
        case 'Лев':
          hintTip = 'LEO';
          break;
        case 'Стрелец':
          hintTip = 'SAGITTARIUS';
          break;
        case 'Телец':
          hintTip = 'TAURUS';
          break;
        case 'Дева':
          hintTip = 'VIRGO';
          break;
        case 'Козерог':
          hintTip = 'CAPRICORN';
          break;
        case 'Близнецы':
          hintTip = 'GEMINI';
          break;
        case 'Весы':
          hintTip = 'LIBRA';
          break;
        case 'Водолей':
          hintTip = 'AQUARIUS';
          break;
        case 'Рак':
          hintTip = 'CANCER';
          break;
        case 'Скорпион':
          hintTip = 'SCORPIO';
          break;
        case 'Рыбы':
          hintTip = 'PISCES';
          break;
      }
      return hintTip;
    },
  },
};
</script>

<style lang="sass" scoped>
@import "src/style/application"

.couple
  display: flex
  flex-direction: column
  align-items: center
  color: grey-color('500')
</style>
