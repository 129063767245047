<template>
  <div class="date-form">
    <div :class="['item-title', { 'hide-input': !showCheckbox }]">
      <check-box hide-details :input-value="checkboxValue" @change="toggleMap" />
      <span @click="toggleMap">{{ $t(title) }}</span>
    </div>
    <v-overlay :z-index="9998" :opacity="0" :value="showSlider"></v-overlay>
    <v-tooltip
      top
      v-model="showSlider"
      :z-index="9999"
      :max-width="267"
      :min-width="267"
      :nudge-bottom="2"
      content-class="tooltip-transparent"
    >
      <date-range-slider
        v-if="showSlider"
        v-model="sliderModel"
        v-click-outside="onSliderBlur"
        :max="sliderOpts[datePartToEdit].max"
        :min="sliderOpts[datePartToEdit].min"
        @click:append="onControlClick('add')"
        @click:prepend="onControlClick('subtract')"
        @change="onSliderChange"
      />
      <template v-slot:activator="{ attrs }">
        <div class="items-container" v-bind="attrs" ref="tooltipAttach">
          <div v-if="$i18n.locale === 'en'" class="item">
            <div :class="['part', { active: datePartActive('days') }]" @click="onClick('days')">
              {{ days }}
            </div>
            <span class="date-divider">.</span>
            <div :class="['part', { active: datePartActive('months') }]" @click="onClick('months')">
              {{ months }}
            </div>
            <span class="date-divider">.</span>
            <div :class="['part big', { active: datePartActive('years') }]" mask="####" @click="onClick('years')">
              {{ years }}
            </div>
          </div>

          <div v-else-if="$i18n.locale === 'de'" class="item">
            <div :class="['part', { active: datePartActive('months') }]" @click="onClick('months')">
              {{ months }}
            </div>
            <span class="date-divider">.</span>
            <div :class="['part', { active: datePartActive('days') }]" @click="onClick('days')">
              {{ days }}
            </div>
            <span class="date-divider">.</span>
            <div :class="['part big', { active: datePartActive('years') }]" mask="####" @click="onClick('years')">
              {{ years }}
            </div>
          </div>

          <div class="item">
            <div :class="['part', { active: datePartActive('hours') }]" @click="onClick('hours')">
              {{ hours }}
            </div>
            <span class="date-divider">:</span>
            <div :class="['part', { active: datePartActive('minutes') }]" @click="onClick('minutes')">
              {{ minutes }}
            </div>
          </div>
        </div>
      </template>
    </v-tooltip>
  </div>
</template>

<script>
import moment from 'moment';
import debounce from 'lodash/debounce';
import DateRangeSlider from './DateRangeSlider';
import CheckBox from '@/elements/components/check-box';

export default {
  name: 'DateFormItem',
  components: { DateRangeSlider, CheckBox },
  props: {
    value: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    showCheckbox: {
      type: Boolean,
      default: false,
    },
    checkboxValue: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.updateDateFromProp();
  },
  data() {
    return {
      currentDate: moment(),
      days: 0,
      months: 0,
      years: 0,
      hours: 0,
      minutes: 0,
      showSlider: false,
      datePartToEdit: 'days',
      sliderModel: 0,
      sliderOpts: {
        days: {
          min: 0,
          max: 31,
        },
        months: {
          min: 0,
          max: 12,
        },
        years: {
          min: 1900,
          max: new Date().getFullYear() + 100,
        },
        hours: {
          min: 0,
          max: 24,
        },
        minutes: {
          min: 0,
          max: 60,
        },
      },
    };
  },
  watch: {
    value: {
      deep: true,
      handler: function () {
        this.updateDateFromProp();
      },
    },
  },
  computed: {
    datePartActive() {
      return (name) => this.showSlider && name === this.datePartToEdit;
    },
  },
  methods: {
    toggleMap() {
      if (this.showCheckbox) {
        this.$emit('toggle-map');
      }
    },
    updateDateFromProp() {
      this.currentDate = moment(`${this.value.date} ${this.value.time}`, 'DD.MM.YYYY HH:mm');
      this.parseDate();
    },
    parseDate() {
      this.days = this.currentDate.format('DD');
      this.months = this.currentDate.format('MM');
      this.years = this.currentDate.format('YYYY');
      this.hours = this.currentDate.format('HH');
      this.minutes = this.currentDate.format('mm');
    },
    onClick(name) {
      if (!this.showSlider) {
        this.showSlider = true;
        this.datePartToEdit = name;
        this.sliderModel = this[name];
      }
    },
    onSliderChange(slideValue) {
      const diff = slideValue - this[this.datePartToEdit];
      const action = diff < 0 ? 'subtract' : 'add';

      this.currentDate[action](Math.abs(diff), this.datePartToEdit);
      this.onChange();
      this.parseDate();
    },
    onSliderBlur() {
      this.showSlider = false;
    },
    onControlClick(action) {
      this.currentDate[action](1, this.datePartToEdit);
      this.onChange();
      this.parseDate();
      this.sliderModel = this[this.datePartToEdit];
    },
    onChange: debounce(function () {
      this.$emit('input', {
        date: this.currentDate.format('DD.MM.YYYY'),
        time: this.currentDate.format('HH:mm'),
      });
    }, 250),
  },
};
</script>

<style scoped lang="sass">
.date-form
  position: relative
  color: grey-color('500')

.items-container
  display: flex

.part
  display: flex
  flex-direction: column
  width: base-unit(27)
  align-items: center
  background-color: blue-color('200')
  border-radius: base-unit(5)
  padding: base-unit(2)
  pointer-events: all
  cursor: pointer
  &.big
    width: base-unit(45)
  &.active
    color: blue-color()

.item
  display: flex
  align-items: center
  &:last-child
    margin-left: base-unit(10)

.slider-container
  position: absolute
  bottom: base-unit(40)
  right: base-unit(-30)
  width: base-unit(267)
  +medium-devices
    left: 0

.item-title
  display: flex
  align-items: flex-start
  color: grey-color('400')
  +base-font-size
  margin-bottom: base-unit(10)
  cursor: pointer
  ::v-deep .v-input__control
    margin: 0
  ::v-deep .v-input--selection-controls
    padding: 0
  &.hide-input
    cursor: default
    ::v-deep .v-input--selection-controls__input
      display: none

.tooltip-transparent
  opacity: 1 !important
  background-color: transparent
  box-shadow: none
  pointer-events: unset
  padding: 0
</style>
