<template>
  <TableTemplate :title="$t('options.tableAspects')" transition-speed="0.5s">
    <table :class="{ 'ph-loading--table': showSkeleton }">
      <thead>
        <tr>
          <th></th>
          <template v-for="(planet, i) in columnAspectingObjectsList">
            <v-tooltip bottom content-class="tooltip-white" :key="planet.name">
              <template v-slot:activator="{ on, attrs }">
                <th :data-column="i + 1" v-bind="attrs" v-on="on">
                  <div class="img-center">
                    <img :src="planet.path" :alt="planet.name" :width="imgSize" :height="imgSize" />
                  </div>
                </th>
              </template>
              <planet-view :data="planet" />
            </v-tooltip>
          </template>
        </tr>
      </thead>
      <tbody>
        <tr v-for="row in rowAspectingObjectsList" :key="row.name">
          <v-tooltip bottom content-class="tooltip-white">
            <template v-slot:activator="{ on, attrs }">
              <td v-bind="attrs" v-on="on">
                <div class="img-center">
                  <img v-if="row.path" :src="row.path" :alt="row.name" :width="imgSize" :height="imgSize" />
                  <span v-else>
                    {{ row.name }}
                  </span>
                </div>
              </td>
            </template>
            <component :is="row.component" :data="row" />
          </v-tooltip>
          <template v-for="(column, i) in columnAspectingObjectsList">
            <v-tooltip
              bottom
              content-class="tooltip-white"
              :key="column.name"
              :disabled="!aspectMap[row.name][column.name]"
            >
              <template v-slot:activator="{ on, attrs }">
                <td
                  v-bind="attrs"
                  v-on="on"
                  :data-column="i + 1"
                  @mouseenter="onMouseEnter(i + 1, aspectMap[row.name][column.name])"
                  @mouseleave="onMouseLeave(i + 1, aspectMap[row.name][column.name])"
                >
                  <div class="img-center">
                    <img
                      v-if="aspectMap[row.name][column.name]"
                      :src="aspectMap[row.name][column.name].path"
                      :alt="aspectMap[row.name][column.name].name"
                      :width="imgSize"
                      :height="imgSize"
                    />
                  </div>
                </td>
              </template>
              <aspect-view :data="aspectMap[row.name][column.name]" />
            </v-tooltip>
          </template>
        </tr>
      </tbody>
    </table>
  </TableTemplate>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { ChartType } from 'astro-chart';

import TableTemplate from './TableTemplate';
import { ASPECT_NAME_TRANSLATION, CUSP_NUMERICAL_NAMES, PLANET_NAMES_TRANSLATION } from '@/types';
import { PlanetView, CuspView, AspectView } from '@/elements/pages/chart/chart/components/tooltip/views';
import HighlightMixin from '@/elements/pages/chart/highlight-mixin';

export default {
  name: 'AspectsTable',
  components: { AspectView, PlanetView, CuspView, TableTemplate },
  mixins: [HighlightMixin],
  data() {
    return {
      imgSize: 16,
    };
  },
  computed: {
    ...mapState('ChartModule', ['chartType', 'firstPersonData', 'secondPersonData']),
    ...mapGetters('ChartModule', ['aspectModels', 'showSkeleton', 'isDoubleChart']),
    planetImages() {
      return Object.keys(PLANET_NAMES_TRANSLATION).map((planet) => ({
        name: planet,
        // eslint-disable-next-line no-undef
        path: require(`@/assets/planets/${planet}.svg`),
      }));
    },
    aspectImages() {
      return Object.keys(ASPECT_NAME_TRANSLATION).reduce((acc, aspect) => {
        acc[aspect] = {
          name: aspect,
          // eslint-disable-next-line no-undef
          path: require(`@/assets/aspects/${aspect.charAt(0).toUpperCase() + aspect.slice(1)}.svg`),
        };
        return acc;
      }, {});
    },
    rowAspectingObjectsList() {
      if (!this.firstPersonData.planetModels) {
        return [];
      }

      const aspectingObjectsList = this.buildPlanetList(this.firstPersonData.planetModels);
      if (this.chartType === ChartType.TRANSIT) {
        aspectingObjectsList.push(...this.buildCuspList(this.firstPersonData.cuspModels));
      }

      return aspectingObjectsList;
    },
    columnAspectingObjectsList() {
      if (!this.isDoubleChart) {
        return this.rowAspectingObjectsList;
      }

      return this.secondPersonData.planetModels ? this.buildPlanetList(this.secondPersonData.planetModels) : [];
    },
    aspectMap() {
      const isNatal = !this.isDoubleChart;
      const isTransit = this.chartType === ChartType.TRANSIT;

      const colNames = Object.keys(PLANET_NAMES_TRANSLATION);
      const rowNames = [...colNames, ...(isTransit ? Object.keys(CUSP_NUMERICAL_NAMES) : [])];

      return rowNames.reduce((accRow, rowName, index) => {
        const sliceIndex = isNatal ? index : 0;

        accRow[rowName] = colNames.slice(sliceIndex).reduce((accCol, colName) => {
          const currentAspect = (this.aspectModels || []).find(
            ({ fromSubject, toSubject }) =>
              (fromSubject.name === rowName && toSubject.name === colName) ||
              (isNatal && fromSubject.name === colName && toSubject.name === rowName),
          );

          if (currentAspect) {
            accCol[colName] = {
              ...this.aspectImages[currentAspect.name],
              desiredAngle: currentAspect.desiredAngle,
              fromSubject: currentAspect.fromSubject,
              toSubject: currentAspect.toSubject,
              aspectType: currentAspect.type,
              model: currentAspect,
            };
          } else {
            accCol[colName] = false;
          }

          return accCol;
        }, {});
        return accRow;
      }, {});
    },
  },
  methods: {
    setHighlight(columnIndex, isHighlighted) {
      const selectedElements = document.querySelectorAll(
        `td[data-column="${columnIndex}"], th[data-column="${columnIndex}"]`,
      );
      Array.from(selectedElements).forEach((el) => {
        el.classList.toggle('highlight', isHighlighted);
      });
    },
    onMouseEnter(columnIndex, aspect) {
      this.setHighlight(columnIndex, true);
      if (aspect) {
        this.highlightElements([aspect.model]);
      }
    },
    onMouseLeave(columnIndex, aspect) {
      this.setHighlight(columnIndex, false);
      if (aspect) {
        this.removeHighlight([aspect.model]);
      }
    },
    buildPlanetList(planetModels) {
      return this.planetImages.map(({ name, path }) => {
        const planet = planetModels[name];
        return {
          name,
          path,
          relativeAngle: planet.relativeAngle,
          zodiac: planet.zodiac.name,
          cusp: planet.cusp?.name,
          component: 'planet-view',
        };
      });
    },
    buildCuspList(cuspModels) {
      return Object.values(cuspModels).map(({ name, relativeAngle, zodiac }) => ({
        name,
        relativeAngle,
        zodiac: zodiac.name,
        component: 'cusp-view',
      }));
    },
  },
};
</script>

<style scoped lang="sass">
@import "src/style/application"

td,th
  max-width: base-unit(30)
  max-height: base-unit(30)
  width: base-unit(30)
  height: base-unit(30)
  padding: 0
  cursor: pointer

td:not(:last-child),
th:not(:last-child)
  border-right: thin solid rgba(0, 0, 0, 0.12)

.img-center
  display: flex
  align-items: center
  justify-content: center

table
  border-spacing: 0
  width: 100%
  border: thin solid rgba(0, 0, 0, 0.12)
  border-radius: base-unit(4)

thead tr:hover
  background-color: inherit

tr:hover td:first-child:hover ~ td
  background-color: inherit

tr:hover td:not(:first-child:hover)
  background-color: #eeeeee

  &:hover
    background-color: inherit

.highlight
  background-color: #eeeeee

td
  border-top: thin solid grey-color('300')

  &:first-child:hover
    background-color: #eeeeee

th:not(:first-child):hover
  background-color: #eeeeee
</style>
