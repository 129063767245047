import { mapGetters, mapState } from 'vuex';
import { Event } from 'astro-chart';

import ChartMixin from '../chart-mixin';
import doubleChartSettings from './settings';
import { enrichPlanetsWithOrbs, transformCuspListToCuspDto } from '../utils';

export default {
  mixins: [ChartMixin],
  data() {
    return {
      elementId: 'double-chart',
      size: 500,
      chartSettings: doubleChartSettings,
    };
  },
  computed: {
    ...mapState('ChartModule', [
      'firstPersonData',
      'secondPersonData',
      'doubleMapDetails',
      'mapIsLoading',
      'drawOnePartOfMap',
      'dataForPartialMapRender',
    ]),
    ...mapGetters('ChartModule', ['currentOrbsConfig']),
    chartData() {
      if (this.drawOnePartOfMap) {
        const { planets, cusps } = this.dataForPartialMapRender.params;

        return {
          planets: enrichPlanetsWithOrbs(planets, this.currentOrbsConfig),
          cusps: transformCuspListToCuspDto(cusps),
        };
      } else {
        return {
          planets: enrichPlanetsWithOrbs(this.firstPersonData.params.planets, this.currentOrbsConfig),
          outerPlanets: enrichPlanetsWithOrbs(this.secondPersonData.params.planets, this.currentOrbsConfig),
          cusps: transformCuspListToCuspDto(this.firstPersonData.params.cusps),
          outerCusps: transformCuspListToCuspDto(this.secondPersonData.params.cusps),
        };
      }
    },
  },
  methods: {
    handleEvent(event) {
      const { eventType, data } = event.detail;

      ChartMixin.methods.handleEvent.call(this, event);

      switch (eventType) {
        case Event.UPDATE_OUTER_PLANETS:
          this.setPlanetModels({
            secondPersonData: data,
          });
          break;
        case Event.UPDATE_OUTER_CUSPS:
          this.setCuspModels({
            secondPersonData: data,
          });
          break;
      }
    },
  },
};
