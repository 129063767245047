<template>
  <div v-if="isLoggedIn" class="user-profile">
    <span class="user-profile__sidebar-collapse-btn-placeholder"></span>
    <div id="my-profile">
      <template v-if="!isMobileScreen">
        <trial-info-tooltip v-if="isTrialPlan">
          <span class="user-profile__label">{{ $t('tariff') }}: </span>
          <span class="user-profile__answer" @click="onGoToTariffs">{{ selectedTariffPlanTranslation }}</span>
        </trial-info-tooltip>
        <div v-else>
          <span class="user-profile__label">{{ $t('tariff') }}: </span>
          <span class="user-profile__answer" @click="onGoToTariffs">{{ selectedTariffPlanTranslation }} </span>
        </div>
      </template>
    </div>
    <div class="user-profile__buttons">
      <support-mobile />
      <my-profile />
    </div>
  </div>
  <div v-else class="sign-in">
    <astro-button color="light" :title="$t('chartPage.create')" @click="onSignUpClick" class="sign-in__sign-up-btn" />
    {{ $t('or') }}
    <astro-button color="light" :title="$t('login.enter')" @click="onSignInClick" class="sign-in__sign-in-btn" />
    <support-mobile log-out />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AstroButton from '@/elements/components/AstroButton';
import MyProfile from './MyProfile';
import TrialInfoTooltip from './TrialInfoTooltip';
import { TARIFF_PLAN } from '@/types';
import SupportMobile from '@/elements/pages/chart/actions/user-info-block/SupportMobile';

export default {
  name: 'UserDataMobile',
  components: {
    AstroButton,
    MyProfile,
    TrialInfoTooltip,
    SupportMobile,
  },
  computed: {
    ...mapGetters('UserModule', ['isLoggedIn']),
    ...mapGetters('UserModule', ['selectedTariffPlanTranslation', 'selectedTariffPlan']),
    isTrialPlan() {
      return this.selectedTariffPlan === TARIFF_PLAN.TRIAL;
    },
  },
  methods: {
    onSignUpClick() {
      this.$router.push({ name: 'signUp' });
    },
    onSignInClick() {
      this.$router.push({ name: 'signIn' });
    },
    onGoToTariffs() {
      this.$router.push({ name: 'tariffs' });
    },
  },
};
</script>

<style lang="sass" scoped>
.user-profile
  align-items: center
  justify-content: space-between
  &__label
    color: grey-color('400')
  &__answer
    color: blue-color('primary')
    cursor: pointer
    &:hover
      color: grey-color('500')

  &__buttons
    display: flex
    align-items: center
    gap: base-unit(10)

.sign-in
  position: relative
  align-items: baseline
  justify-content: center

  +medium-devices
    justify-content: flex-end

  &__sign-up-btn
    margin-right: base-unit(5)
  &__sign-in-btn
    margin-left: base-unit(5)

.user-profile,
.sign-in
  display: flex
  width: 100%
  padding: base-unit(19) base-unit(10) base-unit(10)
  color: grey-color('500')
</style>
