import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('ChartModule', ['chart']),
  },
  methods: {
    highlightElements(elements) {
      this.chart.highlightElements(elements);
      elements.forEach((model) => {
        model.hover();
      });
    },
    removeHighlight(elements) {
      this.chart.removeHighlight();
      elements.forEach((model) => {
        model.unHover();
      });
    },
  },
};
