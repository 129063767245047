<template>
  <div v-if="isLoggedIn" class="user-profile" id="my-profile">
    <trial-info-tooltip v-if="isTrialPlan">
      <span class="user-profile__label">{{ $t('tariff') }}: </span>
      <span class="user-profile__answer" @click="onGoToTariffs">{{ selectedTariffPlanTranslation }}</span>
    </trial-info-tooltip>
    <div v-else>
      <span class="user-profile__label">{{ $t('tariff') }}: </span>
      <span class="user-profile__answer" @click="onGoToTariffs">{{ selectedTariffPlanTranslation }} </span>
    </div>
    <my-profile :email="currentUser.email" />
  </div>
  <div v-else class="sign-in">
    <div>
      <span class="sign-in__question">{{ $t('chartPage.firstTime') }}</span>
      <astro-button color="light" :title="$t('chartPage.create')" @click="onSignUpClick" class="sign-in__sign-up-btn" />
    </div>
    <astro-button color="secondary" :title="$t('login.enter')" @click="onSignInClick" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import AstroButton from '@/elements/components/AstroButton';
import MyProfile from './MyProfile';
import TrialInfoTooltip from './TrialInfoTooltip';
import { TARIFF_PLAN } from '@/types';

export default {
  name: 'UserDataDesktop',
  components: {
    AstroButton,
    MyProfile,
    TrialInfoTooltip,
  },
  computed: {
    ...mapGetters('UserModule', ['isLoggedIn']),
    ...mapGetters('UserModule', ['currentUser', 'selectedTariffPlanTranslation', 'selectedTariffPlan']),
    isTrialPlan() {
      return this.selectedTariffPlan === TARIFF_PLAN.TRIAL;
    },
  },
  methods: {
    onSignUpClick() {
      this.$router.push({ name: 'signUp' });
    },
    onSignInClick() {
      this.$router.push({ name: 'signIn' });
    },
    onGoToTariffs() {
      this.$router.push({ name: 'tariffs' });
    },
  },
};
</script>

<style lang="sass" scoped>
.user-profile
  position: relative
  display: flex
  justify-content: space-between
  color: grey-color('500')
  background: #FFF
  padding: base-unit(8.5) 0
  &__label
    color: grey-color('400')
  &__answer
    color: blue-color('primary')
    cursor: pointer
    &:hover
      color: grey-color('500')

.sign-in
  display: flex
  align-items: baseline
  justify-content: space-between
  background: #fff

  &__question
    color: grey-color('400')

  &__sign-up-btn
    margin-left: base-unit(10)
    display: inline
</style>
