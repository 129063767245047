<template>
  <div class="chart-wrapper">
    <div class="chart">
      <div class="chart__meta">
        <slot name="meta"></slot>
      </div>
      <div v-if="showSkeleton" class="chart__container">
        <chart-placeholder />
      </div>
      <div class="chart__container" :class="{ hidden: showSkeleton }" :id="chartId" />
      <chart-buttons @save-chart="onSaveChart" @delete-chart="onDeleteChart" />
      <chart-tooltip :data="chartTooltip.data" :active="chartTooltip.active" />
      <chart-prompt :data="chartPrompt.data" :active="chartPrompt.active" @close="onChartPromptClose" />
      <chart-zoom-slider v-if="showSlider && !isMobileScreen" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';

import ChartTooltip from './components/tooltip/ChartTooltip';
import ChartButtons from './components/ChartButtons';
import ChartPlaceholder from './components/ChartPlaceholder';
import ChartZoomSlider from './components/ChartZoomSlider';
import ChartPrompt from './components/tooltip/ChartPrompt';

export default {
  name: 'Chart',
  components: {
    ChartTooltip,
    ChartButtons,
    ChartPlaceholder,
    ChartZoomSlider,
    ChartPrompt,
  },
  props: {
    chartId: {
      type: String,
      required: true,
    },
    chartTooltip: {
      type: Object,
      required: true,
    },
    chartPrompt: {
      type: Object,
      required: true,
    },
    showSlider: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('UserModule', ['notifications']),
    ...mapGetters('ChartModule', ['showSkeleton', 'mapName', 'chartIdForRevert']),
  },
  methods: {
    ...mapActions('UserModule', ['addNotification', 'removeNotification']),
    ...mapActions('ChartModule', ['saveChart', 'deleteChart', 'revertChart']),
    onSaveChart() {
      this.chartIdForRevert ? this.onRevert() : this.onShowModal();
    },
    async onShowModal() {
      let isSubmitted;
      if (this.$i18n.locale === 'en') {
        isSubmitted = await this.$showConfirmDialog(
          'Want to add this chart to the database? Please name it',
          'Save to the database',
          '',
          'Chart name',
          this.mapName,
          this.saveChart,
        );
      } else if (this.$i18n.locale === 'de') {
        isSubmitted = await this.$showConfirmDialog(
          // eslint-disable-next-line max-len
          'Möchten Sie diesen Chart in Ihre Bibliothek hinzufügen? Bitte bennen Sie den Chart, um ihn einfacher finden zu können. ',
          'In meiner Bibliothek speichern',
          '',
          'Name der Karte',
          this.mapName,
          this.saveChart,
        );
      }

      if (isSubmitted) {
        this.showSaveMessage();
      } else {
        //this.showDeleteMessage();
      }
    },
    async onDeleteChart() {
      await this.deleteChart();
      this.showDeleteMessage();
    },
    async onRevert() {
      await this.revertChart();
      this.showSaveMessage();
    },
    showDeleteMessage() {
      if (this.$i18n.locale === 'en') {
        this.addNotification({
          text: `${this.mapName} removed from database`,
          action: this.onRevert,
        });
      } else if (this.$i18n.locale === 'de') {
        this.addNotification({
          text: `${this.mapName} wirde aus der Datenbank entfernt`,
          action: this.onRevert,
        });
      }
    },
    showSaveMessage() {
      if (this.$i18n.locale === 'en') {
        this.addNotification({
          text: `${this.mapName}  has been saved to the database`,
          action: this.onDeleteChart,
        });
      } else if (this.$i18n.locale === 'de') {
        this.addNotification({
          text: `${this.mapName} wurde ohne Namen in der Bibliothek gespeichert`,
          action: this.onDeleteChart,
        });
      }
    },
    onChartPromptClose() {
      this.$emit('close-chart-prompt');
    },
  },
  mounted() {
    if (!this.$cookies.get('demo')) {
      this.addNotification({
        text: this.$i18n.t('notifications.demo'),
        interval: 30000,
        img: 'stars-demo',
        remember: 'demo',
      });
    }
  },
  beforeDestroy() {
    this.notifications.forEach((note) => {
      if (note.remember === 'demo') {
        this.removeNotification(note);
        //sessionStorage.setItem(note.remember, 'checked');
        this.$cookies.set(note.remember, 'checked', '7d');
      }
    });
  },
};
</script>

<style scoped lang="sass">
.chart
  display: flex
  flex-direction: column
  background-color: white
  align-items: center
  align-self: stretch
  +large-devices
    height: 100vh
    max-height: 100vh
    width: -webkit-fill-available
  +extra-large-devices
    padding: base-unit(10)
    padding-left: calc(#{base-unit(10)} + #{$sidebar-width-collapsed})
  +high-resolution-devices
    padding-left: base-unit(10)

  &-wrapper
    position: relative
    display: flex
    width: 100%
    height: 100%
    flex: 1
    align-items: center
    justify-content: center

  &__meta
    flex: 0 1 auto

.chart__container
  flex: 1 1 auto
  width: 100%
  padding: base-unit(10)
  +large-devices
    width: auto
    aspect-ratio: 1
    display: contents
    ::v-deep svg
      margin: base-unit(10)

.new-chart-button
  height: base-unit(36)
  width: base-unit(158)
  margin: base-unit(30) 0
  white-space: nowrap
  border-radius: base-unit(100)
  font-size: base-unit(14)
  font-weight: 500
  color: white
  .v-icon
    color: white
    margin-right: base-unit(8)
  +medium-devices
    display: none

.hidden
  position: fixed
  display: block
  top: -100000px
</style>
