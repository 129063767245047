<template>
  <div class="root">
    {{ requestParams && JSON.stringify(requestParams) }}
    {{ planetsPosition && JSON.stringify(planetsPosition) }}
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'HiddenChartInfo',
  computed: {
    ...mapState('ChartModule', ['requestParams']),
    ...mapGetters('ChartModule', ['planetsPosition']),
  },
};
</script>

<style scoped lang="sass">
.root
  visibility: hidden
  position: absolute
  right: 0
  bottom: 0
</style>
