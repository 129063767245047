<template>
  <v-menu bottom left :offset-y="true" nudge-bottom="10" class="support-mobile">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        dark
        icon
        v-bind="attrs"
        v-on="on"
        :class="{
          'support-mobile__button': true,
          'support-mobile__button--log-out': logOut,
        }"
      >
        <img :src="require('@/assets/icons/support-blue.svg')" alt="Мой профиль" class="support-mobile__button-icon" />
      </v-btn>
    </template>

    <v-list>
      <v-list-item>
        <a class="support-mobile__link" href="https://astronova.io/help" target="_blank">{{ $t('sidebar.center') }}</a>
      </v-list-item>
      <v-list-item @click="openChat">
        <span>{{ $t('sidebar.chat') }}</span>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'SupportMobile',
  props: {
    logOut: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    openChat() {
      // eslint-disable-next-line no-undef
      carrotquest.open();
    },
  },
};
</script>

<style lang="sass" scoped>
.support-mobile

  &__button
    background: blue-color('100')
    border-radius: 50%
    height: base-unit(36)
    width: base-unit(36)

    +medium-devices
      display: none

    &--log-out
      position: absolute
      right: base-unit(10)
      bottom: base-unit(10)

    &-icon
      width: base-unit(20)
      height: base-unit(20)

  &__link
    display: inline-block
    width: 100%
    text-decoration: none

.v-list-item
  min-height: base-unit(27)
  padding: 0 base-unit(20)

.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled)
  color: blue-color() !important
  &:hover
    background-color: #FFF

  &.my-profile__sign-out-option
    color: red-color() !important

.theme--light.v-list-item:focus::before
  opacity: 0

.v-list-item--link:before
  background-color: #FFF

.v-menu__content
  width: fit-content
  +astro-box-shadow
</style>
