<template>
  <range-slider v-model="sliderValue" :max="100" :min="-100" postfix="%" @change="onChange" />
</template>

<script>
import RangeSlider from '@/elements/atoms/RangeSlider';
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'DateFormOrbsSlider',
  components: { RangeSlider },
  computed: {
    ...mapGetters('ChartModule', ['orbsScale']),
    sliderValue() {
      return this.transformOrbsScaleToValue(this.orbsScale);
    },
  },
  mounted() {
    if (localStorage.getItem('orbsScale')) {
      this.setOrbsScale(JSON.parse(localStorage.getItem('orbsScale')));
    }
    window.addEventListener('beforeunload', this.setOrbsScaleValueToStorage);
  },
  beforeDestroy() {
    if (localStorage.getItem('orbsScale')) {
      localStorage.removeItem('orbsScale');
    }
    window.removeEventListener('beforeunload', this.setOrbsScaleValueToStorage);
  },
  methods: {
    ...mapActions('ChartModule', ['setOrbsScale']),
    onChange(value) {
      this.setOrbsScaleValue(value);
    },
    transformOrbsScaleToValue(value) {
      switch (true) {
        case value > 1:
          return Math.round((value - 1) * 100);
        case value < 1:
          return -Math.round((1 - value) * 2 * 100);
        default:
          return 0;
      }
    },
    transformValueToOrbsScale(value) {
      switch (true) {
        case value > 0:
          return value / 100 + 1;
        case value < 0:
          return 1 - Math.abs(value) / 200;
        default:
          return 1;
      }
    },
    setOrbsScaleValue(value) {
      const orbsScaleValue = this.transformValueToOrbsScale(value);
      this.setOrbsScale(orbsScaleValue);
    },
    setOrbsScaleValueToStorage() {
      localStorage.setItem('orbsScale', JSON.stringify(this.orbsScale));
    },
  },
};
</script>
