<template>
  <v-slider
    v-model="sliderValue"
    :max="100"
    :min="0"
    :thumb-size="14"
    thumb-label="always"
    append-icon="add"
    prepend-icon="remove"
    color="primary"
    thumb-color="primary"
    track-color="#C3D7DD"
    vertical
    class="slider"
    @click:append="onAddClick"
    @click:prepend="onSubtractClick"
  >
    <template v-slot:thumb-label="{ value }">
      <span>{{ value }}</span>
      <span>%</span>
    </template>
  </v-slider>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'ChartZoomSlider',
  computed: {
    ...mapGetters('ChartModule', ['zoomValue']),
    sliderValue: {
      set(value) {
        this.setZoomValue(value);
      },
      get() {
        return this.zoomValue;
      },
    },
  },
  methods: {
    ...mapActions('ChartModule', ['setZoomValue']),
    triggerUpdate(value) {
      this.$emit('input', value);
    },
    onAddClick() {
      this.setZoomValue(this.zoomValue + 10);
    },
    onSubtractClick() {
      this.setZoomValue(this.zoomValue - 10);
    },
  },
};
</script>

<style lang="sass" scoped>
.slider
  position: absolute
  left: base-unit(25)
  bottom: base-unit(20)
  +extra-large-devices
    left: base-unit(107)
  +high-resolution-devices
    left: base-unit(25)

  ::v-deep .v-slider--vertical
    height: base-unit(182)

  ::v-deep .v-slider__thumb-label
    margin-left: base-unit(10)
    background: transparent !important
    color: grey-color('500')
    height: base-unit(10) !important
</style>
