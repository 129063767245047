import { CUSP_NUMERICAL_NAMES } from '@/types';

export const enrichPlanetsWithOrbs = (planets, orbsConfig) =>
  planets.map((planet) => ({
    ...planet,
    active: true,
    orbConfig: orbsConfig[planet.name],
  }));

export const transformCuspListToCuspDto = (cusps) => cusps.map((angle) => ({ angle }));

export const enrichCuspsWithOrbs = (cusps, orbsConfig) => {
  const cuspNames = Object.values(CUSP_NUMERICAL_NAMES);

  return cusps.map((cusp, index) => ({
    ...cusp,
    orbConfig: orbsConfig[cuspNames[index]],
  }));
};
