/* eslint-disable no-undef */

export const defaultPlanetSettings = {
  shape: 'circularImage',
  imagePadding: { left: 8, top: 8, right: 8, bottom: 8 },
  color: {
    border: '#E9E9E9',
    background: 'white',
    highlight: {
      border: '#E9E9E9',
      background: 'white',
    },
  },
};

export const primaryPlanetSettings = {
  shape: 'circularImage',
  imagePadding: { left: 8, top: 8, right: 8, bottom: 8 },
  color: {
    border: '#167EA2',
    background: 'white',
    highlight: {
      border: '#167EA2',
      background: 'white',
    },
  },
};

export const planetsRu = [
  {
    id: 1,
    label: 'Chiron',
    title: 'Chiron',
    image: require('@/assets/planets/Chiron.svg'),
    ...defaultPlanetSettings,
  },
  {
    id: 2,
    label: 'Jupiter',
    title: 'Jupiter',
    image: require('@/assets/planets/Jupiter.svg'),
    ...defaultPlanetSettings,
  },
  {
    id: 3,
    label: 'Lilith',
    title: 'Lilith',
    image: require('@/assets/planets/Lilith.svg'),
    ...defaultPlanetSettings,
  },
  {
    id: 4,
    label: 'Mars',
    title: 'Mars',
    image: require('@/assets/planets/Mars.svg'),
    ...defaultPlanetSettings,
  },
  {
    id: 5,
    label: 'Mercury',
    title: 'Mercury',
    image: require('@/assets/planets/Mercury.svg'),
    ...defaultPlanetSettings,
  },
  {
    id: 6,
    label: 'Moon',
    title: 'Moon',
    image: require('@/assets/planets/Moon.svg'),
    ...defaultPlanetSettings,
  },
  {
    id: 7,
    label: 'Neptune',
    title: 'Neptune',
    image: require('@/assets/planets/Neptune.svg'),
    ...defaultPlanetSettings,
  },
  {
    id: 8,
    label: 'Pluto',
    title: 'Pluto',
    image: require('@/assets/planets/Pluto.svg'),
    ...defaultPlanetSettings,
  },
  {
    id: 9,
    label: 'Saturn',
    title: 'Saturn',
    image: require('@/assets/planets/Saturn.svg'),
    ...defaultPlanetSettings,
  },
  {
    id: 10,
    label: 'Sun',
    title: 'Sun',
    image: require('@/assets/planets/Sun.svg'),
    ...defaultPlanetSettings,
  },
  {
    id: 11,
    label: 'Uranus',
    title: 'Uranus',
    image: require('@/assets/planets/Uranus.svg'),
    ...defaultPlanetSettings,
  },
  {
    id: 12,
    label: 'Venus',
    title: 'Venus',
    image: require('@/assets/planets/Venus.svg'),
    ...defaultPlanetSettings,
  },
];
export const planetsDe = [
  {
    id: 1,
    label: 'Chiron',
    title: 'Chiron',
    image: require('@/assets/planets/Chiron.svg'),
    ...defaultPlanetSettings,
  },
  {
    id: 2,
    label: 'Jupiter',
    title: 'Jupiter',
    image: require('@/assets/planets/Jupiter.svg'),
    ...defaultPlanetSettings,
  },
  {
    id: 3,
    label: 'Lilith',
    title: 'Lilith',
    image: require('@/assets/planets/Lilith.svg'),
    ...defaultPlanetSettings,
  },
  {
    id: 4,
    label: 'Mars',
    title: 'Mars',
    image: require('@/assets/planets/Mars.svg'),
    ...defaultPlanetSettings,
  },
  {
    id: 5,
    label: 'Mercury',
    title: 'Merkur',
    image: require('@/assets/planets/Mercury.svg'),
    ...defaultPlanetSettings,
  },
  {
    id: 6,
    label: 'Moon',
    title: 'Mond',
    image: require('@/assets/planets/Moon.svg'),
    ...defaultPlanetSettings,
  },
  {
    id: 7,
    label: 'Neptune',
    title: 'Neptun',
    image: require('@/assets/planets/Neptune.svg'),
    ...defaultPlanetSettings,
  },
  {
    id: 8,
    label: 'Pluto',
    title: 'Pluto',
    image: require('@/assets/planets/Pluto.svg'),
    ...defaultPlanetSettings,
  },
  {
    id: 9,
    label: 'Saturn',
    title: 'Saturn',
    image: require('@/assets/planets/Saturn.svg'),
    ...defaultPlanetSettings,
  },
  {
    id: 10,
    label: 'Sun',
    title: 'Sonne',
    image: require('@/assets/planets/Sun.svg'),
    ...defaultPlanetSettings,
  },
  {
    id: 11,
    label: 'Uranus',
    title: 'Uranus',
    image: require('@/assets/planets/Uranus.svg'),
    ...defaultPlanetSettings,
  },
  {
    id: 12,
    label: 'Venus',
    title: 'Venus',
    image: require('@/assets/planets/Venus.svg'),
    ...defaultPlanetSettings,
  },
];

export const networkOptions = {
  clickToUse: false,
  interaction: {
    dragNodes: false,
    dragView: false,
    zoomView: false,
    selectable: false,
    selectConnectedEdges: false,
    tooltipDelay: 100,
  },
  nodes: {
    borderWidth: 2,
    borderWidthSelected: 2,
    size: 20,
    font: { color: 'transparent' },
    shapeProperties: {
      useBorderWithImage: true,
    },
  },
  edges: {
    color: '#167EA2',
    width: 2,
    selectionWidth: 2,
  },
};

/* eslint-enable no-undef */
