<template>
  <div class="chart-page">
    <div class="chart-page__content">
      <user-data-mobile v-if="!extraLargeScreen" />
      <component :is="chartComponent" />
      <div class="chart-page__content--right">
        <user-data-desktop v-if="extraLargeScreen" />
        <actions />
      </div>
      <scroll-button class="chart-page__scroll-button" />
    </div>

    <v-dialog v-model="showErrorPopup" persistent max-width="290">
      <v-card class="chart-page__error">
        <cross-button class="chart-page__error-close-btn" @click="resetError" />
        <v-card-title>
          {{ $t('error') }}
        </v-card-title>
        <v-card-text>
          {{ errorText }}
        </v-card-text>
      </v-card>
    </v-dialog>
    <hidden-chart-info />
    <trial-banner-container />
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import { ChartType } from 'astro-chart';

import CrossButton from '@/elements/components/CrossButton';
import { NatalChart, DoubleChart, TransitChart } from './chart';
import Actions from './actions';
import urlSaver from './url-saver';
import HiddenChartInfo from './HiddenChartInfo';
import UserDataMobile from '@/elements/pages/chart/actions/user-info-block/UserDataMobile';
import UserDataDesktop from '@/elements/pages/chart/actions/user-info-block/UserDataDesktop';
import { TrialBannerContainer } from '@/elements/components/banners/trial';
import ScrollButton from '@/elements/components/ScrollButton';

export default {
  name: 'ChartPage',
  mixins: [urlSaver],
  components: {
    HiddenChartInfo,
    Actions,
    NatalChart,
    DoubleChart,
    TransitChart,
    CrossButton,
    UserDataMobile,
    UserDataDesktop,
    TrialBannerContainer,
    ScrollButton,
  },
  methods: {
    ...mapActions('ChartModule', ['resetError', 'setOrbsScale']),
    ...mapActions('UserModule', ['closeTrialBanner']),
  },
  computed: {
    ...mapState('ChartModule', ['errorText', 'chartType']),
    ...mapGetters('UserModule', ['isTrialBannerOpened']),
    showErrorPopup: {
      get() {
        return this.errorText.length;
      },
      set() {},
    },
    chartComponent() {
      const charts = {
        [ChartType.NATAL]: NatalChart,
        [ChartType.TRANSIT]: TransitChart,
        [ChartType.SOLAR]: DoubleChart,
        [ChartType.SYNASTRY]: DoubleChart,
        [ChartType.PROGRESSION]: DoubleChart,
        [ChartType.DIRECTION]: DoubleChart,
      };

      return this.chartType ? charts[this.chartType] : null;
    },
    extraLargeScreen() {
      return this.$mq === 'exlg' || this.$mq === 'hr';
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      const { name } = from;
      if (name === 'new-map') {
        vm.setOrbsScale();
      }
    });
  },
  beforeRouteLeave(to, from, next) {
    if (this.isTrialBannerOpened) {
      this.closeTrialBanner();
      next();
    } else {
      next();
    }
  },
};
</script>

<style scoped lang="sass">
.chart-page
  width: 100%
  position: relative
  max-width: 100vw

  &__content
    display: flex
    flex-direction: column
    width: 100%
    min-height: 100vh
    +large-devices
      flex-direction: row
      min-height: unset

    &--right
      display: flex
      flex-direction: column
      width: 100%
      box-sizing: border-box
      z-index: 1
      padding: base-unit(10) base-unit(10) base-unit(86)
      +large-devices
        width: 33%
        min-width: base-unit(500)
        padding: base-unit(20) base-unit(30) 0 0
        overflow-y: auto
        max-height: 100vh
        flex-grow: 0

  &__error
    position: relative

    &-close-btn
      position: absolute
      left: calc(100% - 25px)
      top: 5px

  &__scroll-button
    position: absolute
    right: base-unit(10)
    bottom: base-unit(30)
    z-index: 1
</style>
