<template>
  <transition name="collapse" v-bind="$attrs" @enter="enter" @after-enter="afterEnter" @leave="leave">
    <slot></slot>
  </transition>
</template>

<script>
export default {
  name: 'CollapseTransition',
  props: {
    transitionSpeed: {
      type: String,
      default: '0.3s',
    },
  },
  methods: {
    enter(element) {
      element.style.width = getComputedStyle(element).width;
      element.style.position = 'absolute';
      element.style.visibility = 'hidden';
      element.style.height = 'auto';
      element.style.transitionDuration = this.transitionSpeed;

      const height = getComputedStyle(element).height;

      element.style.width = null;
      element.style.position = null;
      element.style.visibility = null;
      element.style.height = 0;

      getComputedStyle(element).height;

      requestAnimationFrame(() => {
        element.style.height = height;
      });
    },
    afterEnter(element) {
      element.style.height = 'auto';
    },
    leave(element) {
      element.style.height = getComputedStyle(element).height;

      getComputedStyle(element).height;

      requestAnimationFrame(() => {
        element.style.height = 0;
      });
    },
  },
};
</script>

<style scoped lang="sass">
*
  will-change: height
  backface-visibility: hidden

.collapse-enter-active, .collapse-leave-active
  transition-timing-function: linear
  transition-property: opacity, height
  overflow: hidden

.collapse-enter, .collapse-leave-to
  height: 0
  opacity: 0
</style>
