<template>
  <div>
    <div class="date-form-container" :class="{ 'ph-loading--content': showSkeleton }">
      <date-form-item
        v-if="firstPersonData"
        v-model="firstPersonData"
        :title="firstPerson.name"
        :show-checkbox="showCheckbox"
        :checkbox-value="firstMapPartIsOn"
        @toggle-map="toggleMap('first')"
      />
      <template v-if="isDoubleChart">
        <swap-button @click="swapCharts" />
        <date-form-item
          v-if="secondPersonData"
          v-model="secondPersonData"
          :title="secondPerson.name"
          :show-checkbox="showCheckbox"
          :checkbox-value="secondMapPartIsOn"
          @toggle-map="toggleMap('second')"
        />
      </template>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapState } from 'vuex';

import { DateFormItem, SwapButton } from './index.js';
import { PageSubtitle } from '@/elements/components/page';
import { PERSON } from '@/types';
import { ChartType } from 'astro-chart';

export default {
  name: 'DateTimeActions',
  components: {
    DateFormItem,
    SwapButton,
    PageSubtitle,
  },
  data() {
    return {
      PERSON,
    };
  },
  computed: {
    ...mapState('ChartModule', {
      requestParams: 'requestParams',
      firstPerson: 'firstPersonData',
      secondPerson: 'secondPersonData',
      mapPartToDraw: 'mapPartToDraw',
      firstMapPartIsOn: 'firstMapPartIsOn',
      secondMapPartIsOn: 'secondMapPartIsOn',
    }),
    ...mapGetters('ChartModule', ['meta', 'showSkeleton', 'isDoubleChart']),
    ...[PERSON.FIRST, PERSON.SECOND].reduce((acc, person) => {
      acc[person] = {
        get() {
          return this.meta ? this.meta[person] : null;
        },
        async set(value) {
          await this.setDateTime({
            person,
            value,
          });
        },
      };

      return acc;
    }, {}),
    showCheckbox() {
      const { chartType } = this.requestParams;
      return chartType !== ChartType.NATAL;
    },
  },
  methods: {
    ...mapActions('ChartModule', ['swapCharts', 'setDateTime', 'toggleMap']),
  },
};
</script>
<style lang="sass" scoped>

@mixin row-display
  flex-direction: row
  .swap-button
    position: unset
    align-self: flex-end
    transform: none
    padding: 0
    margin: base-unit(5) base-unit(20)
  .date-form
    margin-bottom: 0
    display: block

@mixin col-display
  flex-direction: column
  position: relative
  .swap-button
    position: absolute
    left: calc(100% - #{base-unit(15)})
    top: base-unit(65)
    transform: rotate(90deg)
    margin: 0
    padding: 0
  .date-form
    margin-bottom: base-unit(10)
    display: flex
    flex-direction: column
    align-items: center

.date-form-container
  display: flex
  +col-display
  align-items: center

  +medium-devices
    align-items: baseline
    +row-display

    &.sidebar-expanded
      +col-display

  +large-devices
    &.sidebar-expanded
      +row-display
</style>
