<template>
  <meta-container :class="{ 'ph-loading--content': showSkeleton }">
    <span class="chart-name">{{ mapName }}</span>
    <meta-item v-if="meta && firstDateFormData" :data="firstDateFormData" :metaTitle="firstPersonData.name" />
    <meta-item
      v-if="meta && secondDateFormData"
      :data="secondDateFormData"
      :double-map-details="doubleMapDetails"
      :metaTitle="secondPersonData.name"
    />
  </meta-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { MetaContainer, MetaItem } from '../components/meta';

export default {
  name: 'DoubleChartMeta',
  components: {
    MetaContainer,
    MetaItem,
  },
  computed: {
    ...mapState('ChartModule', ['firstPersonData', 'secondPersonData', 'doubleMapDetails']),
    ...mapGetters('ChartModule', ['meta', 'showSkeleton', 'mapName']),
    firstDateFormData() {
      return this.meta.firstPersonData;
    },
    secondDateFormData() {
      return this.meta.secondPersonData;
    },
  },
};
</script>

<style lang="sass" scoped>
.chart-name
  font-size: base-unit(14)
  font-weight: 500
  color: grey-color('500')
</style>
