<template>
  <tooltip-view :name="name" :description="content" />
</template>

<script>
import TooltipView from './BaseView';
import { convertToDms } from '@/utils/degrees';
import { CUSP_NUMERICAL_NAMES, ZODIAC_NAMES_TRANSLATION } from '@/types';
import { i18n } from '@/plugins/i18n';

export default {
  name: 'CuspView',
  components: { TooltipView },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    name() {
      return `${CUSP_NUMERICAL_NAMES[this.data.name]} ${i18n.t('house')}`;
    },
    content() {
      const { relativeAngle, zodiac } = this.data;
      const angle = convertToDms(relativeAngle);
      const zodiacName = i18n.t(ZODIAC_NAMES_TRANSLATION[zodiac]);

      return `(${angle}) ${zodiacName}`;
    },
  },
};
</script>

<style scoped lang="sass"></style>
