<template>
  <div v-if="isTrialBannerOpened">
    <trial-banner v-if="isExpired" class="trial-banner" @close="onClose">
      <template v-slot:image>
        <img src="@/assets/trial/expiration-star.svg" alt="star" />
      </template>
      <template v-slot:header>Тестовый период тарифа <span class="tariff-name">Премиум</span> закончился</template>
      <template v-slot:info> Чтобы сохранить доступ ко всем функциям, подключите тариф </template>
      <template v-slot:actions>
        <astro-button class="btn" @click="onSubscribeToPremium">Перейти к тарифу Премиум</astro-button>
        <astro-button class="btn" color="secondary" @click="onClose">Оставить тариф Стандарт</astro-button>
      </template>
    </trial-banner>
    <trial-banner v-else class="trial-banner" @close="onClose">
      <template v-slot:image>
        <img src="@/assets/trial/info-star.svg" alt="star" />
      </template>
      <template v-slot:header>Вы используете тестовый период тарифа <span class="tariff-name">Премиум</span> </template>
      <template v-slot:info>
        <div>До конца тестового периода {{ daysCount }}.</div>
        <div>Подключите тариф и сохраните доступ ко всем функциям</div>
      </template>
      <template v-slot:actions>
        <astro-button class="btn" @click="onGoToTariffs">Перейти к тарифам</astro-button>
      </template>
    </trial-banner>
  </div>
</template>

<script>
import TrialBanner from '@/elements/components/banners/trial/TrialBanner';
import AstroButton from '@/elements/components/AstroButton';
import { mapGetters, mapActions } from 'vuex';
import { TARIFF_PLAN, TARIFF_PLAN_TRANSLATION } from '@/types';

export default {
  name: 'TrialExpirationBanner',
  components: { TrialBanner, AstroButton },
  computed: {
    ...mapGetters('UserModule', ['tariffExpireDays', 'trialBannerOpened', 'isTrialBannerOpened']),
    isExpired() {
      return this.tariffExpireDays === 0;
    },
    daysCount() {
      let wordRemained = 'осталось';
      let wordDays = 'дней';
      switch (true) {
        case this.tariffExpireDays === 1:
          wordRemained = 'остался';
          wordDays = 'день';
          break;
        case this.tariffExpireDays > 1 && this.tariffExpireDays < 5:
          wordDays = 'дня';
          break;
        default:
          break;
      }
      return `${wordRemained} ${this.tariffExpireDays} ${wordDays}`;
    },
  },
  methods: {
    ...mapActions('UserModule', ['closeTrialBanner', 'setSubscription', 'addNotification', 'fetchUser']),
    onClose() {
      this.closeTrialBanner();
    },
    onGoToTariffs() {
      this.$router.push({ name: 'tariffs' });
    },
    async onSubscribeToPremium() {
      const plan = TARIFF_PLAN.PREMIUM;
      const amount = 390;

      try {
        await this.setSubscription({ plan, amount });
        await this.addNotification({ text: `Вы успешно оформили подписку ${TARIFF_PLAN_TRANSLATION[plan]}.` });
        await this.fetchUser().catch();
        await this.$showConfirmDialog('Тариф вступит в силу в течение 2-х минут. Обновите страницу.', 'Ок');
        this.closeTrialBanner();
      } catch {
        await this.addNotification({ text: 'Не удалось оформить подписку. Попробуйте еще раз позже.' });
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.trial-banner
  position: fixed
  bottom: base-unit(40)
  right: base-unit(20)
  z-index: 2
  +small-devices
    right: base-unit(50)

.tariff-name
  color: blue-color('primary')

.btn
  width: 100%
</style>
