<template>
  <a :href="href" class="details-link" :target="target">
    {{ $t('more') }}
  </a>
</template>

<script>
export default {
  name: 'DetailsLink',
  props: {
    href: {
      type: String,
      default: '',
    },
    target: {
      type: String,
      default: '_self',
    },
  },
};
</script>

<style lang="sass" scoped>
.details-link
  display: inline-block
  color: grey-color('400')
  line-height: base-unit(19)
  text-decoration: none
  border-bottom: 1px grey-color('400') dotted
</style>
