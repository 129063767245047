<template>
  <v-menu bottom left :offset-y="true" nudge-bottom="10" min-width="150" class="my-profile" attach="#my-profile">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-if="extraLargeScreen" dark icon v-bind="attrs" v-on="on" class="my-profile__arrow-btn" @click="onToggle">
        <span class="my-profile__email">{{ email }}</span>
        <img
          :src="require('@/assets/icons/arrow-down-blue.svg')"
          alt="Мой профиль"
          class="my-profile__arrow-btn-icon"
          :class="{ rotate: isExpanded }"
        />
      </v-btn>
      <v-btn v-else dark icon v-bind="attrs" v-on="on" class="my-profile__user-btn">
        <img :src="require('@/assets/icons/person.svg')" :alt="$t('myProfile')" class="my-profile__user-btn-icon" />
      </v-btn>
    </template>

    <v-list>
      <v-list-item @click="$router.push({ name: 'profile' })">
        <span>{{ $t('myProfile') }}</span>
      </v-list-item>
      <v-list-item @click="$router.push({ name: 'tariffs' })">
        <span>{{ $t('sidebar.plans') }}</span>
      </v-list-item>
      <v-list-item class="my-profile__sign-out-option" @click="onLogOut">
        <span>{{ $t('logout') }}</span>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'MyProfile',
  props: {
    email: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isExpanded: false,
    };
  },
  computed: {
    extraLargeScreen() {
      return this.$mq === 'exlg' || this.$mq === 'hr';
    },
  },
  methods: {
    ...mapActions('UserModule', ['logOut']),
    ...mapActions('ChartModule', ['removeChartHistory', 'setDefaultChartHistoryPageNumber']),
    onToggle() {
      this.isExpanded = !this.isExpanded;
    },
    onLogOut() {
      this.logOut();
      this.removeChartHistory();
      this.setDefaultChartHistoryPageNumber();
    },
  },
};
</script>

<style lang="sass" scoped>
.my-profile
  &__user-btn
    border-radius: 50%
    height: base-unit(36)
    width: base-unit(36)
    background: blue-color('100')
    +medium-devices
      height: base-unit(24)
      width: base-unit(24)
    &-icon
      width: 24px
      height: 24px
      +medium-devices
        width: 16px
        height: 16px

  &__arrow-btn
    width: auto
    height: auto
    font-weight: 400
    font-size: base-unit(16)
    letter-spacing: 0
    text-transform: lowercase
    &-icon
      width: 100%
      height: 100%
      transition: all 0.3s linear
      &.rotate
        transform: rotate(180deg)

  &__email
    margin-right: base-unit(6)
    color: blue-color('primary')

.v-list-item
  min-height: base-unit(27)
  padding: 0 base-unit(20)

.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled)
  color: blue-color() !important
  &:hover
    background-color: #FFF

  &.my-profile__sign-out-option
    color: red-color() !important

.theme--light.v-list-item:focus::before
  opacity: 0

.v-list-item--link:before
  background-color: #FFF

.v-menu__content
  +astro-box-shadow
  +large-devices
    right: 0
    left: auto !important
</style>
