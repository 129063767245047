<template>
  <div class="actions">
    <banner-container v-if="!isDesktopScreen" />
    <date-form-action v-show="meta" />
    <coords-table v-show="showCoords" />
    <aspects-table v-show="showAspects" />
    <model-interpretation />
    <status-assessment v-if="isStatusAssessmentEnabled" />
    <strong-objects v-if="showStrongObjects && isStrongObjectsEnabled" />
    <chain-graphs v-if="showChains && isChainsEnabled" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import DateFormAction from './date-form/DateFormAction';
import { AspectsTable, CoordsTable, StrongObjects, StatusAssessment, ModelInterpretation } from './tables';
import { isProduction } from '@/utils/common';
const ChainGraphs = () => import('./tables/ChainGraphs');
const BannerContainer = () => import('@/elements/components/banners');

const isChainsEnabled = process.env.VUE_APP_SHOW_CHAINS;
const isStrongObjectsEnabled = process.env.VUE_APP_SHOW_STRONG_OBJECTS;
const isStatusAssessmentEnabled = process.env.VUE_APP_SHOW_STATUS_ASSESSMENT;
export default {
  name: 'Actions',
  components: {
    ModelInterpretation,
    AspectsTable,
    CoordsTable,
    ChainGraphs,
    DateFormAction,
    StrongObjects,
    BannerContainer,
    StatusAssessment,
  },
  data() {
    return {
      isProduction,
      isChainsEnabled,
      isStrongObjectsEnabled,
      isStatusAssessmentEnabled,
    };
  },
  computed: {
    ...mapGetters('ChartModule', ['showChains', 'showAspects', 'showCoords', 'meta', 'showStrongObjects']),
  },
};
</script>

<style scoped lang="sass">
.actions
  display: flex
  flex-direction: column
  width: 100%
  height: auto
  padding: base-unit(20) base-unit(30) 0 0
  box-sizing: border-box
  z-index: 1
  border: base-unit(1) solid grey-color('10')
  padding: 0 base-unit(20) 0
  border-radius: base-unit(4)

  +medium-devices
    margin-top: base-unit(20)

  *
    &:last-child
      border-bottom: none
</style>
