<template>
  <div class="notification-banner">
    <cross-button class="notification-banner__close-btn" color="grey" @click="onClose" />
    <div class="notification-banner__image">
      <slot name="image" />
    </div>
    <div class="notification-banner__header">
      <slot name="header" />
    </div>
    <div class="notification-banner__info">
      <slot name="info" />
    </div>
    <div class="actions">
      <slot name="actions" />
    </div>
  </div>
</template>

<script>
import CrossButton from '@/elements/components/CrossButton';

export default {
  name: 'NotificationBanner',
  components: { CrossButton },
  methods: {
    onClose() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="sass" scoped>
.notification-banner
  display: flex
  flex-direction: column
  align-items: center
  width: base-unit(280)
  padding: base-unit(20) base-unit(20) base-unit(40) base-unit(20)
  color: grey-color('500')
  background-color: #ffffff
  border-radius: base-unit(4)
  box-sizing: border-box
  +astro-box-shadow
  +small-devices
    width: base-unit(320)

  &__close-btn
    position: absolute
    right: base-unit(13)
    top: base-unit(13)

  &__image
    margin-bottom: base-unit(10)

  &__header
    margin-bottom: base-unit(10)
    max-width: base-unit(260)
    font-weight: 700
    text-align: center

  &__info
    display: flex
    flex-direction: column
    align-items: center
    margin-bottom: base-unit(20)
    text-align: center

  .actions
    display: flex
    flex-direction: column
    align-items: center
    gap: base-unit(10)
    width: 100%
</style>
