<template>
  <div :class="{ 'range-slider-wrapper': true, vertical: vertical }">
    <button class="range-slider-button" @click="onPrepend">
      <img :src="require('@/assets/icons/minus-blue.svg')" alt="prepend" />
    </button>
    <div class="range-slider">
      <input
        v-model="rangeValue"
        class="range-slider__input"
        type="range"
        :min="min"
        :max="max"
        :steps="steps"
        @change="onChange"
      />
      <div ref="selector" class="range-slider__selector">
        <span ref="value" class="range-slider__value vertical-value" />
      </div>
      <div class="range-slider__progress">
        <span ref="fill" class="range-slider__fill" />
      </div>
    </div>
    <button class="range-slider-button" @click="onAppend">
      <img :src="require('@/assets/icons/plus-blue.svg')" alt="append" />
    </button>
  </div>
</template>

<script>
export default {
  name: 'RangeSlider',
  props: {
    value: {
      type: Number,
      default: 0,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 100,
    },
    steps: {
      type: Number,
      default: 1,
    },
    buttonStep: {
      type: Number,
      default: 10,
    },
    postfix: {
      type: String,
      default: '',
    },
    vertical: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    rangeValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.setSelectorValue(value);
      },
    },
    range() {
      return this.max - this.min;
    },
  },
  watch: {
    value(value) {
      this.setSelectorValue(value);
    },
  },
  mounted() {
    this.$refs.value.innerHTML = `${this.value}${this.postfix}`;
    this.setSelectorValue(this.value);
  },
  methods: {
    onChange(event) {
      const value = +event.target.value;
      this.$emit('change', value);
    },
    onAppend() {
      const value = +this.value;
      if (value >= this.max - this.buttonStep) {
        this.$emit('change', this.max);
      } else {
        this.$emit('change', value + this.buttonStep);
      }
    },
    onPrepend() {
      const value = +this.value;
      if (value <= this.min + this.buttonStep) {
        this.$emit('change', this.min);
      } else {
        this.$emit('change', value - this.buttonStep);
      }
    },
    setSelectorValue(value) {
      this.$refs.value.innerHTML = `${value}${this.postfix}`;
      this.$refs.selector.style.left = `${((value - this.min) / this.range) * 100}%`;
      this.$refs.fill.style.width = `${((value - this.min) / this.range) * 100}%`;
    },
  },
};
</script>

<style lang="sass" scoped>
.range-slider-wrapper
  display: flex
  align-items: center
  gap: base-unit(15)
  width: 100%
  padding-top: base-unit(15)

.range-slider-button
  position: relative
  display: flex
  align-items: center
  justify-content: center
  width: base-unit(14)
  height: base-unit(14)

  &::before
    content: ''
    position: absolute
    top: 50%
    left: 50%
    width: base-unit(28)
    height: base-unit(28)
    background-color: rgba(22, 126, 162, .3)
    opacity: 0
    border-radius: 50%
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1)
    transform: translate(-50%, -50%)

  &:hover
    &::before
      opacity: 1

.range-slider
  position: relative
  display: flex
  width: 100%

  &__input
    -webkit-appearance: none
    width: 100%
    height: base-unit(24)
    border: none

    &::-webkit-slider-thumb
      -webkit-appearance: none
      position: relative
      z-index: 3
      width: base-unit(14)
      height: base-unit(14)
      cursor: pointer
      border: none
      outline: none

    &::-moz-range-thumb
      -webkit-appearance: none
      position: relative
      z-index: 3
      width: base-unit(14)
      height: base-unit(14)
      cursor: pointer
      border: none
      outline: none

  &__selector
    position: absolute
    z-index: 2
    top: 50%
    left: 50%
    width: base-unit(14)
    height: base-unit(14)
    transform: translate(-50%, -50%)
    background-color: blue-color('primary')
    border-radius: 50%
    pointer-events: none

    &::before
      content: ''
      position: absolute
      top: 0
      left: 0
      width: base-unit(14)
      height: base-unit(14)
      background-color: rgba(22, 126, 162, .3)
      border-radius: 50%
      transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1)

  &:hover
    .range-slider__selector
      &::before
        transform: scale(2)

  &:active
    .range-slider__selector
      &::before
        transform: scale(3)

  &__value
    position: absolute
    top: base-unit(-22)
    left: 50%
    width: base-unit(22)
    height: base-unit(22)
    font-size: base-unit(12)
    color: grey-color('500')
    text-align: center
    transform: translateX(-50%)
    pointer-events: none

  &__progress
    position: absolute
    top: 50%
    width: 100%
    background-color: blue-color('50')
    height: base-unit(2)
    transform: translateY(-50%)

  &__fill
    position: absolute
    top: 50%
    left: 0
    width: 50%
    height: base-unit(2)
    background-color: blue-color('primary')
    transform: translateY(-50%)
.vertical
  transform: rotate(-90deg)

  .range-slider-button
    transform: rotate(90deg)

  .vertical-value
      top: base-unit(22)
      left: base-unit(-6)
      transform: rotate(90deg)
</style>
