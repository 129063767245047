<template>
  <v-slider
    v-bind="$attrs"
    v-on="$listeners"
    class="slider"
    thumb-label="always"
    append-icon="add"
    prepend-icon="remove"
    :tick-size="1"
  >
    <template v-slot:thumb-label="{ value }">
      {{ label(value) }}
    </template>
  </v-slider>
</template>
<script>
export default {
  name: 'DateRangeSlider',
  computed: {
    label() {
      return (value) => (value < 10 ? '0' + value : value);
    },
  },
};
</script>
<style lang="sass" scoped>

.slider
  background: #FFF
  border-radius: base-unit(4)
  +astro-box-shadow
  padding: base-unit(15) base-unit(23)
  margin-bottom: 0
  align-items: center
  width: base-unit(282) !important
  ::v-deep .v-icon
    color: blue-color()
    font-size: base-unit(18)
  ::v-deep .v-messages
    display: none
  ::v-deep .v-input__slot
    margin-bottom: 0
  ::v-deep .v-slider__thumb-label
    background: transparent !important
    color: grey-color('500')
    height: base-unit(10) !important
  ::v-deep .v-input__icon
    width: base-unit(14)
    height: base-unit(14)
  ::v-deep .v-input__append-outer,
  ::v-deep .v-input__prepend-outer
    margin: 0 !important
</style>
