import { mapActions, mapGetters, mapState } from 'vuex';
import AstroChart, { CHART_EVENT_TYPE, Event, ChartType } from 'astro-chart';
import { isProduction } from '@/utils/common';

export default {
  data() {
    return {
      chartSettings: {},
      elementId: '',
      chartTooltip: {
        active: false,
        data: null,
      },
      chartPrompt: {
        active: false,
        data: null,
      },
    };
  },
  computed: {
    ...mapState('ChartModule', ['chart', 'chartType', 'mapIsLoading', 'drawOnePartOfMap', 'mapName']),
    ...mapState('UserModule', ['settings']),
    ...mapGetters('ChartModule', ['meta', 'currentAspectsConfig', 'isCosmogram', 'zoomValue', 'orbsScale']),
    isZoomEnabled() {
      const { zoomEnabled } = this.settings;
      return (this.isMobileScreen && zoomEnabled.mobile) || (!this.isMobileScreen && zoomEnabled.desktop);
    },
    aspectConfigMap() {
      return Object.values(this.currentAspectsConfig).reduce((configMap, aspect) => {
        configMap.set(aspect.name, aspect);
        return configMap;
      }, new Map());
    },
  },
  watch: {
    mapIsLoading() {
      this.renderChart();
    },
    chartType() {
      this.destroyChart();
    },
    drawOnePartOfMap() {
      this.destroyChart();
      this.renderChart();
    },
    zoomValue(value) {
      this.onChangeZoom(value);
    },
    orbsScale() {
      this.renderChart();
    },
  },
  methods: {
    ...mapActions('ChartModule', [
      'setChart',
      'setAspectModels',
      'setCuspModels',
      'setPlanetModels',
      'updateChart',
      'showInterpretation',
      'closeInterpretation',
      'resetMapDrawToFull',
      'setZoomValue',
      'setOrbsScale',
    ]),
    createChart() {
      if (this.mapIsLoading) {
        return;
      }

      const chartType = this.drawOnePartOfMap ? ChartType.NATAL : this.chartType;
      const chart = AstroChart.createChart(chartType, this.size, `#${this.elementId}`, {
        ...this.chartSettings,
        debug: !isProduction,
        zoom: {
          enabled: this.isZoomEnabled,
        },
      });

      sessionStorage.setItem('mapName', this.mapName);

      this.setChart(chart);
    },
    destroyChart() {
      if (this.chart) {
        this.closeInterpretation();
        this.chart.destroy();
      }

      this.setChart(null);
    },
    renderChart() {
      if (this.mapIsLoading) {
        return;
      }

      if (!this.chart) {
        this.createChart();
      }

      this.chart.render({
        ...this.chartData,
        aspectConfig: this.aspectConfigMap,
        cosmogram: this.isCosmogram,
        calculatedAspectType: this.settings.calculatedAspectType,
        calculatedOrbType: this.settings.calculatedOrbType,
        orbsScale: this.orbsScale,
      });
    },
    onUpdateAspectModels(data) {
      this.setAspectModels(data);
    },
    onUpdatePlanetModels(data) {
      this.setPlanetModels({ firstPersonData: data });
    },
    onUpdateCuspModels(data) {
      this.setCuspModels({ firstPersonData: data });
    },
    onTooltipShow(data) {
      if (this.isMobileScreen) {
        this.chartPrompt.active = true;
        this.chartPrompt.data = data;
      } else {
        this.chartTooltip.active = true;
        this.chartTooltip.data = data;
      }
    },
    onTooltipHide() {
      this.chartTooltip.active = false;
    },
    onUpdateZoom(data) {
      this.setZoomValue(data);
    },
    onChangeZoom(value) {
      this.setZoomValue(value);
      this.chart.setZoomLevel(value);
    },
    onChartPromptClose() {
      this.chartPrompt.active = false;
    },
    onResize() {
      this.renderChart();
    },
    handleEvent(event) {
      const { eventType, data } = event.detail;

      switch (eventType) {
        case Event.UPDATE_ASPECTS:
          this.onUpdateAspectModels(data);
          break;
        case Event.UPDATE_PLANETS:
          this.onUpdatePlanetModels(data);
          break;
        case Event.UPDATE_CUSPS:
          this.onUpdateCuspModels(data);
          break;
        case Event.SHOW_TOOLTIP:
          this.onTooltipShow(data);
          break;
        case Event.HIDE_TOOLTIP:
          this.onTooltipHide();
          break;
        case Event.SHOW_INTERPRETATION:
          this.showInterpretation(data);
          break;
        case Event.HIDE_INTERPRETATION:
          this.closeInterpretation();
          break;
        case Event.UPDATE_ZOOM:
          this.onUpdateZoom(data);
      }
    },
  },
  created() {
    document.body.addEventListener(CHART_EVENT_TYPE, this.handleEvent, true);
    window.addEventListener('resize', this.onResize);
  },
  mounted() {
    this.createChart();
    this.renderChart();
  },
  async beforeDestroy() {
    this.setZoomValue();
    this.destroyChart();
    this.resetMapDrawToFull();
    document.body.removeEventListener(CHART_EVENT_TYPE, this.handleEvent, true);
    window.removeEventListener('resize', this.onResize);
  },
};
