<template>
  <chart
    :chart-tooltip="chartTooltip"
    :chart-prompt="chartPrompt"
    :chart-id="elementId"
    :show-slider="isZoomEnabled"
    @close-chart-prompt="onChartPromptClose"
  >
    <template v-slot:meta>
      <double-chart-meta />
    </template>
  </chart>
</template>

<script>
import { Event } from 'astro-chart';
import { mapActions } from 'vuex';

import DoubleChartMixin from './double-chart-mixin';
import DoubleChartMeta from './DoubleChartMeta';
import Chart from '../Chart';
import { enrichCuspsWithOrbs, enrichPlanetsWithOrbs, transformCuspListToCuspDto } from '../utils';

export default {
  name: 'TransitChart',
  mixins: [DoubleChartMixin],
  components: { DoubleChartMeta, Chart },
  data() {
    return {
      elementId: 'transit-chart',
    };
  },
  computed: {
    chartData() {
      return {
        planets: enrichPlanetsWithOrbs(this.firstPersonData.params.planets, this.currentOrbsConfig),
        outerPlanets: enrichPlanetsWithOrbs(this.secondPersonData.params.planets, this.currentOrbsConfig),
        cusps: enrichCuspsWithOrbs(
          transformCuspListToCuspDto(this.firstPersonData.params.cusps),
          this.currentOrbsConfig,
        ),
        outerCusps: transformCuspListToCuspDto(this.secondPersonData.params.cusps),
      };
    },
  },
  methods: {
    ...mapActions('ChartModule', ['setHouseAspectModels']),
    handleEvent(event) {
      const { eventType, data } = event.detail;

      DoubleChartMixin.methods.handleEvent.call(this, event);

      switch (eventType) {
        case Event.UPDATE_HOUSE_ASPECTS:
          this.setHouseAspectModels(data);
          break;
      }
    },
  },
};
</script>
