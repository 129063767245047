<template>
  <TableTemplate :title="$t('options.analyzeMap')">
    <template v-if="strongPlanets.length">
      <page-subtitle class="subtitle" :class="{ 'ph-loading--title': showSkeleton }">
        <span>{{ $t('strongPlanets') }}</span>
        <details-link href="https://astronova.io/page29773656.html" target="_blank" class="details-link" />
      </page-subtitle>

      <div class="planets-container" :class="{ 'ph-loading--content': showSkeleton }">
        <div
          v-for="planet in strongPlanets"
          :key="planet"
          class="planet"
          @mouseenter="onHoverPlanet(planet)"
          @mouseleave="onBlurPlanet(planet)"
        >
          <v-tooltip right content-class="tooltip-white">
            <template v-slot:activator="{ on, attrs }">
              <div class="img-center" v-bind="attrs" v-on="on">
                <img
                  width="20"
                  height="20"
                  :src="require(`@/assets/planets/${planet}.svg`)"
                  :alt="planet"
                  class="planet-img"
                />
              </div>
            </template>
            <span>{{ $t(PLANET_NAMES_TRANSLATION[planet]) }}</span>
          </v-tooltip>
        </div>
      </div>
    </template>
    <template v-if="aspectsConfigurations.length">
      <page-subtitle class="subtitle" :class="{ 'ph-loading--title': showSkeleton }">
        {{ $t('config') }}
      </page-subtitle>
      <div
        v-for="(config, index) in aspectsConfigurations"
        :key="`${config.type}-${index}`"
        class="config-container"
        :class="{ 'ph-loading--content': showSkeleton }"
        @mouseenter="onHoverAspects(index)"
        @mouseleave="onBlurAspects(index)"
      >
        <div class="config-name">
          {{ $t(ASPECTS_CONFIGURATION_NAMES[config.type]) }}
        </div>

        <div v-for="planet in config.planets" :key="planet" class="img">
          <v-tooltip right content-class="tooltip-white">
            <template v-slot:activator="{ on, attrs }">
              <div class="img-center" v-bind="attrs" v-on="on">
                <img
                  width="20"
                  height="20"
                  :src="require(`@/assets/planets/${planet}.svg`)"
                  :alt="planet"
                  class="planet-img"
                />
              </div>
            </template>
            <span>{{ $t(PLANET_NAMES_TRANSLATION[planet]) }}</span>
          </v-tooltip>
        </div>
      </div>
    </template>
  </TableTemplate>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import TableTemplate from './TableTemplate';
import { ASPECTS_CONFIGURATION_NAMES, PLANET_NAMES_TRANSLATION } from '@/types';
import { PageSubtitle } from '@/elements/components/page';
import HighlightMixin from '@/elements/pages/chart/highlight-mixin';
import DetailsLink from '@/elements/pages/chart/actions/tables/DetailsLink';

export default {
  name: 'StrongObjects',
  components: { DetailsLink, TableTemplate, PageSubtitle },
  mixins: [HighlightMixin],
  data() {
    return {
      ASPECTS_CONFIGURATION_NAMES,
      PLANET_NAMES_TRANSLATION,
    };
  },
  computed: {
    ...mapState('ChartModule', ['strongPlanets', 'aspectsConfigurations', 'firstPersonData', 'aspectModels']),
    ...mapGetters('ChartModule', ['showSkeleton']),
    aspectConfigurationModels() {
      return this.aspectsConfigurations.map(({ aspects }) => {
        return aspects
          .map(({ angle, firstPlanetName, secondPlanetName }) => {
            return this.aspectModels.find(
              (model) =>
                model.angle === angle &&
                ((model.fromSubject.name === firstPlanetName && model.toSubject.name === secondPlanetName) ||
                  (model.fromSubject.name === secondPlanetName && model.toSubject.name === firstPlanetName)),
            );
          })
          .filter((model) => !!model);
      });
    },
  },
  methods: {
    onHoverAspects(configNumber) {
      const aspectModels = this.aspectConfigurationModels[configNumber];
      this.highlightElements(aspectModels);
    },
    onBlurAspects(configNumber) {
      const aspectModels = this.aspectConfigurationModels[configNumber];
      this.removeHighlight(aspectModels);
    },
    onHoverPlanet(planetName) {
      const planetModel = this.firstPersonData.planetModels[planetName];
      this.highlightElements([planetModel]);
    },
    onBlurPlanet(planetName) {
      const planetModel = this.firstPersonData.planetModels[planetName];
      this.removeHighlight([planetModel]);
    },
  },
};
</script>

<style lang="sass" scoped>
.planets-container
  margin: base-unit(-5) base-unit(-5) base-unit(20)
  display: flex
  flex-wrap: wrap
  &.ph-loading--content
    margin: 0 0 base-unit(20) 0

.planet
  border-radius: base-unit(5)
  border: base-unit(1) solid grey-color('300')
  width: base-unit(35)
  height: base-unit(35)
  display: flex
  flex-direction: column
  align-items: center
  justify-content: space-around
  margin: base-unit(5)
  cursor: pointer
  &:hover
    background-color: blue-color('200')

.subtitle
  color: blue-color()

.config-container
  display: flex
  flex-wrap: wrap
  width: fit-content
  align-items: center
  border: base-unit(1) solid grey-color('300')
  border-radius: base-unit(5)
  padding: base-unit(5)
  cursor: pointer
  &:hover
    background-color: blue-color('200')
  &:not(:last-child)
    margin-bottom: base-unit(10)
  .img
    margin: 0 base-unit(10)

.img-center
  display: flex
  align-items: center
  width: base-unit(20)
.details-link
  margin-left: base-unit(10)
</style>
