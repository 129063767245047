<template>
  <table-template blocked temporarilyBlocked :title="$t('options.integration')">
    <div class="interpretation-container">
      <div v-if="!hasAccess(TARIFF_PLAN.PREMIUM) && isPremiumInterpretation">
        {{ $t('forPremium') }}
      </div>
      <div v-else-if="noInterpretation" class="no-interpretation">
        <span class="no-interpretation__text">Данный аспект не интерпретируется</span>
        <details-link href="https://help.astronova.ru/article/5819" target="_blank" />
      </div>
      <div
        v-else-if="currentInterpretation"
        v-html="preparedInterpretation"
        class="interpretation-container__content"
      />
      <div v-else class="interpretation-container__content">
        Выберите объект для интерпретации на карте - аспект, знак или дом
      </div>
      <v-tooltip
        v-if="showFullInterpretationHint && !noInterpretation && !hasAccess(TARIFF_PLAN.PREMIUM)"
        right
        content-class="tooltip-white"
      >
        <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on" class="interpretation-container__help">Полная интерпретация</span>
        </template>
        {{ $t('forPremium') }}
      </v-tooltip>
    </div>
  </table-template>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import TableTemplate from './TableTemplate';
import DetailsLink from './DetailsLink';
import { TARIFF_PLAN, INTERPRETATION_TYPE } from '@/types';

export default {
  name: 'ModelInterpretation',
  components: { TableTemplate, DetailsLink },
  data() {
    return { TARIFF_PLAN };
  },
  computed: {
    ...mapState('ChartModule', ['currentInterpretation']),
    ...mapGetters('UserModule', ['isLoggedIn']),
    preparedInterpretation() {
      if (this.currentInterpretation && this.currentInterpretation.text) {
        const icon = '<span class="interpretation-text-icon">✦</span>';
        const text = this.currentInterpretation.text;
        return text.replace(/\*/g, icon);
      } else {
        return null;
      }
    },
    isPremiumInterpretation() {
      return this.currentInterpretation && this.currentInterpretation.type === INTERPRETATION_TYPE.ASPECT;
    },
    showFullInterpretationHint() {
      return this.currentInterpretation && this.currentInterpretation.type === INTERPRETATION_TYPE.PLANET;
    },
    noInterpretation() {
      return this.currentInterpretation && !this.currentInterpretation.text;
    },
  },
  methods: {
    ...mapActions('ChartModule', ['closeInterpretation']),
  },
};
// logic for expand
/*:expandByDefault="!!currentInterpretation && isLoggedIn"*/
</script>
<style lang="sass" scoped>
.interpretation-container
  display: flex
  flex-direction: column
  border-radius: base-unit(10)
  padding: base-unit(12)
  box-sizing: border-box
  background-color: white

  &__content
    color: grey-color('500')
    white-space: pre-line

  &__help
    align-self: flex-start
    margin-top: base-unit(20)
    color: grey-color('400')
    line-height: base-unit(19)
    border-bottom: 1px grey-color('400') dotted

.no-interpretation
  display: flex
  flex-direction: column
  align-items: flex-start

  &__text
    margin-bottom: base-unit(10)

.collapse-enter-active,
.collapse-leave-active
  transition: all .3s

.collapse-enter,
.collapse-leave-to
  opacity: 0
</style>
