<template>
  <v-btn dark icon class="scroll-button" @click="onScrollButtonClick">
    <img :src="require('@/assets/icons/arrow.svg')" alt="Мой профиль" class="scroll-btn__icon" />
  </v-btn>
</template>

<script>
export default {
  name: 'ScrollButton',
  methods: {
    onScrollButtonClick() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
  },
};
</script>

<style lang="sass" scoped>
.scroll-button
  background: #ffffff
  border-radius: 50%
  height: base-unit(36)
  width: base-unit(36)
  align-self: flex-end
  +astro-box-shadow('small', rgba(0, 0, 0, 0.075))
  +large-devices
    display: none
  &__icon
    width: 24px
    height: 24px
</style>
