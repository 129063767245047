<template>
  <v-select dense v-bind="$attrs" v-on="$listeners" outlined></v-select>
</template>

<script>
export default {
  name: 'AstroSelect',
};
</script>

<style lang="sass">
.v-select
  .v-input__slot
    fieldset
      border-color: grey-color('75')

  .v-icon
    color: grey-color('75')

  input, .v-select__selection--comma
    color: grey-color('500') !important

  .v-list-item--link:before
    background-color: #FFF

  .v-list .v-list-item--active
    color: blue-color('primary')

.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled)
  color: grey-color('500') !important

.v-list-item--dense .v-list-item__title,
.v-list-item--dense .v-list-item__subtitle,
.v-list--dense .v-list-item .v-list-item__title,
.v-list--dense .v-list-item .v-list-item__subtitle
  font-size: base-unit(16)
  font-weight: normal

.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled)
  &:hover
    background-color: grey-color('10')

.v-text-field--outlined.v-input--is-focused fieldset,
.v-text-field--outlined.v-input--has-state fieldset
  border-width: base-unit(1)
</style>
