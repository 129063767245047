<template>
  <div class="tooltip-view">
    <b>{{ $t(name) }}</b>
    {{ $t(description) }}
  </div>
</template>

<script>
export default {
  name: 'TooltipView',
  props: {
    name: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped lang="sass">
.tooltip-view
  color: inherit
  font-weight: 400

  & > div
    margin: base-unit(3) 0

    &:first-child
      margin-top: 0
    &:last-child
      margin-bottom: 0
</style>
