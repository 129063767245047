<template>
  <TableTemplate
    :blocked="!hasAccess(TARIFF_PLAN.PREMIUM)"
    :title="$t('options.chainPlanets')"
    :alwaysDrawContent="true"
    @toggle="onTableToggle"
  >
    <div class="chains-container" :class="{ 'ph-loading--content': showSkeleton }">
      <div id="graph"></div>
    </div>
  </TableTemplate>
  <!--:blocked="!hasAccess(TARIFF_PLAN.PREMIUM)"-->
</template>
<script>
import debounce from 'lodash/debounce';
import { mapGetters } from 'vuex';
import { Network } from 'vis-network/peer/esm/vis-network';
import { DataSet } from 'vis-data/peer/esm/vis-data';
import { TARIFF_PLAN } from '@/types';

import TableTemplate from './TableTemplate';
import { planetsRu, planetsDe, primaryPlanetSettings, networkOptions } from './chain-config.js';

export default {
  name: 'ChainGraphs',
  components: { TableTemplate },
  data() {
    return {
      TARIFF_PLAN,
    };
  },
  computed: {
    ...mapGetters('ChartModule', ['getChains', 'showSkeleton']),
  },
  mounted() {
    window.addEventListener('resize', this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    onResize: debounce(function () {
      this.createChains();
    }, 200),
    onTableToggle(visible) {
      if (visible) {
        this.createChains();
      }
    },
    createChains() {
      const nodes = [];
      const edges = [];
      const result = [];
      // putting al nodes with default settings to one array
      this.getChains.forEach((chain) => {
        Object.keys(chain).forEach((planetName) => {
          if (chain[planetName].primary) {
            result.push({
              label: planetName,
              level: 0,
              ...chain[planetName],
            });
          } else {
            result.push({
              label: planetName,
              level: 1,
              ...chain[planetName],
            });
          }
        });
      });

      // calculating level for each node
      for (let currentLevel = 1; currentLevel < 12; currentLevel++) {
        result
          .filter((p) => p.level === currentLevel)
          .forEach((planet) => {
            const relatedPlanets = result.filter(({ relatedTo }) => relatedTo === planet.label);
            relatedPlanets.forEach((el) => {
              const index = result.findIndex((t) => t.label === el.label);
              result[index].level = currentLevel + 1;
            });
          });
      }

      //building data
      result.forEach((elem) => {
        // pushing nodes
        let planet;
        if (this.$i18n.locale === 'en') {
          planet = planetsRu.find((p) => p.label === elem.label);
        } else {
          planet = planetsDe.find((p) => p.label === elem.label);
        }
        /*const planet = planets.find((p) => p.label === elem.label);*/
        if (elem.primary) {
          nodes.push({
            ...planet,
            ...primaryPlanetSettings,
            level: elem.level,
          });
        } else {
          nodes.push({ ...planet, level: elem.level });
        }

        //pushing edges
        const relatedPlanetName = elem.relatedTo;

        // not drawing edges that connect planet with themselves
        if (planet.label === relatedPlanetName) {
          return;
        }

        let relatedPlanet;

        if (this.$i18n.locale === 'en') {
          relatedPlanet = planetsRu.find((p) => p.label === relatedPlanetName);
        } else {
          relatedPlanet = planetsDe.find((p) => p.label === relatedPlanetName);
        }

        /*const relatedPlanet = planets.find((p) => p.label === relatedPlanetName);*/
        edges.push({
          from: planet.id,
          to: relatedPlanet.id,
          arrows: {
            to: {
              enabled: true,
              type: 'arrow',
            },
          },
        });
      });

      this.renderNetwork(nodes, edges);
    },
    renderNetwork(nodesData, edgesData) {
      const nodes = new DataSet(nodesData);
      const edges = new DataSet(edgesData);
      const container = document.getElementById('graph');
      const data = {
        nodes,
        edges,
      };
      new Network(container, data, networkOptions);
    },
  },
  watch: {
    // eslint-disable-next-line
    '$i18n.locale': function (newVal, oldVal) {
      this.createChains();
    },
    getChains: {
      deep: true,
      handler(val) {
        if (val) {
          this.createChains();
        }
      },
    },
  },
};
</script>
<style scoped lang="sass">
@import "src/style/application"

.chains-container
  width: base-unit(300)
  display: block
  +small-devices
    width: base-unit(500)
  +medium-devices
    width: base-unit(700)
  +large-devices
    width: base-unit(600)
  +extra-large-devices
    width: base-unit(430)
  +high-resolution-devices
    width: base-unit(450)
  &.ph-loading--content
    width: 100%
    +loader-animation

#graph
  height: base-unit(500)
  ::v-deep .vis-network[style]
    outline: none !important
  ::v-deep canvas
    touch-action: pan-y !important
</style>
