<template>
  <div>
    <date-time-actions class="settings" />

    <div class="btns-container">
      <v-tooltip top content-class="tooltip-white" :disabled="isMobileScreen">
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on" v-if="!chartId" class="chart-btn chart-btn--save" @click.stop="onSaveChart">
            <img :src="require('@/assets/icons/star-outline-blue.svg')" alt="сохранить" />
          </div>
          <div v-bind="attrs" v-on="on" v-else class="chart-btn chart-btn--save" @click="onDeleteChart">
            <img :src="require('@/assets/icons/star-fill-blue.svg')" alt="удалить" />
          </div>
        </template>
        {{ saveMessage }}
      </v-tooltip>
      <v-tooltip top content-class="tooltip-white">
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on" class="chart-btn chart-btn--edit" @click="$router.push({ name: 'edit-map' })">
            <img :src="require('@/assets/icons/edit.svg')" alt="редактировать" />
          </div>
        </template>
        {{ $t('editMap') }}
      </v-tooltip>
      <v-tooltip top content-class="tooltip-white">
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on" class="new-btn" @click="$router.push({ name: 'new-map' })">
            <img :src="require('@/assets/icons/plus.svg')" alt="создать" class="new-btn-icon" />
            <span>{{ $t('newMap') }}</span>
          </div>
        </template>
        {{ $t('createNewMap') }}
      </v-tooltip>
    </div>
  </div>
</template>
<script>
import { DateTimeActions } from '@/elements/pages/chart/actions/date-form';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ChartButtons',
  components: {
    DateTimeActions,
  },
  computed: {
    ...mapGetters('ChartModule', ['chartId']),
    ...mapGetters('UserModule', ['isLoggedIn']),
    saveMessage() {
      return this.chartId ? this.$i18n.t('chartPage.deleteChart') : this.$i18n.t('chartPage.saveChart');
    },
  },
  methods: {
    ...mapActions('UserModule', ['clickModal']),
    onSaveChart() {
      if (this.isLoggedIn) {
        this.$emit('save-chart');
      } else {
        this.clickModal(true);
      }
    },
    onDeleteChart() {
      if (this.isLoggedIn) {
        this.$emit('delete-chart');
      } else {
        this.clickModal(true);
      }
    },
  },
};
</script>
<style lang="sass" scoped>
.btns-container
  display: flex
  align-items: center
  margin-bottom: base-unit(20)
  +medium-devices
    position: absolute
    right: base-unit(35)
    bottom: base-unit(20)
    margin-bottom: base-unit(0)

.chart-btn
  display: flex
  align-items: center
  justify-content: center
  width: base-unit(36)
  height: base-unit(36)
  padding: base-unit(8)
  background-color: blue-color('100')
  +astro-box-shadow('small', rgba(0, 0, 0, 0.075))
  cursor: pointer
  border-radius: base-unit(100)
  +medium-devices
    width: base-unit(41)
    height: base-unit(41)

  &--save
    margin-right: base-unit(10)
    img
      width: base-unit(30)
      height: auto
    +medium-devices
      margin-right: base-unit(12)

  &--edit
    order: 1
    img
      width: base-unit(22)
      height: auto
    +medium-devices
      order: 0
      margin-right: base-unit(10)

.new-btn
  width: auto
  height: base-unit(36)
  margin-right: base-unit(10)
  padding: base-unit(9) base-unit(20)
  background: blue-color()
  border-radius: base-unit(4)
  color: white
  font-size: base-unit(14)
  display: inline-flex
  align-items: center
  justify-content: space-between
  text-decoration: none
  +medium-devices
    font-size: base-unit(16)
    width: base-unit(60)
    height: base-unit(60)
    margin-right: 0
    padding: base-unit(16) base-unit(17)
    border-radius: base-unit(100)
  &-icon
    display: none
    +medium-devices
      display: block
      width: base-unit(26)
      height: base-unit(26)
  span
    +medium-devices
      display: none

.datetime-btn
  background: blue-color('100')
  display: flex
  flex-direction: column
  align-items: center
  justify-content: space-evenly
  border-radius: 50%
  width: base-unit(36)
  height: base-unit(36)
  margin-left: base-unit(12)
  border: none
  img
    width: base-unit(23)
  &.active
    background: blue-color()
  +medium-devices
    display: none

.settings
  margin-top: base-unit(30)
  margin-bottom: base-unit(20)
  text-align: center
  +medium-devices
    display: none
</style>
