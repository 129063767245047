<template>
  <tooltip-view :name="name" :description="content" />
</template>

<script>
import { CUSP_NUMERICAL_NAMES, PLANET_NAMES_TRANSLATION, ZODIAC_NAMES_TRANSLATION } from '@/types';
import { convertToDms } from '@/utils/degrees';

import TooltipView from './BaseView';
import { i18n } from '@/plugins/i18n';

export default {
  name: 'PlanetView',
  components: { TooltipView },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    name() {
      return PLANET_NAMES_TRANSLATION[this.data.name];
    },
    content() {
      const { relativeAngle, zodiac, cusp } = this.data;
      const angle = convertToDms(relativeAngle);
      const zodiacName = i18n.t(ZODIAC_NAMES_TRANSLATION[zodiac]);
      const cuspName = cusp ? ` ${CUSP_NUMERICAL_NAMES[cusp]} ${i18n.t('house')}` : '';

      return `(${angle}) ${zodiacName}${cuspName}`;
    },
  },
};
</script>

<style scoped lang="sass"></style>
