<template>
  <chart
    :chart-tooltip="chartTooltip"
    :chart-prompt="chartPrompt"
    :chart-id="elementId"
    :show-slider="isZoomEnabled"
    @close-chart-prompt="onChartPromptClose"
  >
    <template v-slot:meta>
      <double-chart-meta />
    </template>
  </chart>
</template>

<script>
import doubleChartMixin from './double-chart-mixin';
import DoubleChartMeta from './DoubleChartMeta';
import Chart from '../Chart';

export default {
  name: 'DoubleChart',
  mixins: [doubleChartMixin],
  components: { DoubleChartMeta, Chart },
};
</script>
