import isEmpty from 'lodash/isEmpty';
import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';
import pick from 'lodash/pick';
import omit from 'lodash/omit';
import { ChartType } from 'astro-chart';
import { mapActions, mapState } from 'vuex';

import { buildQueryParamsFromRequestParams, chartQueryParamsList } from '@/utils/query-params';

export default {
  computed: {
    ...mapState('ChartModule', ['chartId', 'mapIsLoading', 'requestParams']),
    query() {
      const newQuery = buildQueryParamsFromRequestParams({
        ...this.requestParams,
        chartId: this.chartId,
      });

      return pickBy(newQuery, identity);
    },
  },
  watch: {
    query: {
      deep: true,
      immediate: true,
      async handler(newQuery, oldQuery) {
        if (JSON.stringify(newQuery) === JSON.stringify(oldQuery) || isEmpty(newQuery)) {
          return;
        }

        await this.updateRouteParams(this.$route.path, newQuery);
      },
    },
    '$route.path': {
      async handler() {
        await this.updateRouteParams(this.$route.path, this.query);
      },
    },
  },
  methods: {
    ...mapActions('ChartModule', ['loadChart', 'addChart', 'addDefaultChart']),
    async updateRouteParams(path, chartQuery) {
      const restQuery = omit(this.$route.query, chartQueryParamsList);

      const query = {
        ...chartQuery,
        ...restQuery,
      };
      await this.$router
        .replace({
          path,
          query,
        })
        .catch((_) => {});
    },
    buildPersonData(data, number = '') {
      return {
        date: data[`birthDate${number}`],
        time: data[`birthTime${number}`],
        place: data[`birthPlace${number}`],
        lat: data[`birthLat${number}`],
        long: data[`birthLong${number}`],
        mapType: data[`mapType${number}`],
        houseSystem: data[`houseSystem${number}`],
        cosmogram: data[`cosmogram${number}`],
      };
    },
    async restoreState() {
      if (this.mapIsLoading) {
        return;
      }

      let chartParams;
      if (this.requestParams.filled) {
        chartParams = this.requestParams;
      } else {
        const { query } = this.$route;
        const chartQueryParams = pick(query, chartQueryParamsList);

        if (Object.keys(chartQueryParams).length) {
          chartParams = {
            chartId: query.chartId,
            chartType: query.chartType,
            firstPersonData: this.buildPersonData(query),
          };
          if (query.chartType !== ChartType.NATAL) {
            chartParams.secondPersonData = this.buildPersonData(query, '2');
          }
        }
      }

      if (chartParams) {
        if (chartParams.chartId) {
          await this.loadChart(chartParams).catch(async () => {
            await this.addChart(chartParams);
          });
        } else {
          await this.addChart(chartParams);
        }
      } else {
        await this.addDefaultChart();
      }
    },
  },
  async created() {
    await this.restoreState();
  },
};
