<template>
  <tooltip-view :name="name" :description="content" />
</template>

<script>
import { AspectType, AspectableType } from 'astro-chart';

import TooltipView from './BaseView';
import { ASPECT_NAME_TRANSLATION, CUSP_NUMERICAL_NAMES, PLANET_NAMES_TRANSLATION } from '@/types';
import { convertToDms } from '@/utils/degrees';
import { i18n } from '@/plugins/i18n';

export default {
  name: 'AspectView',
  components: { TooltipView },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    name() {
      return ASPECT_NAME_TRANSLATION[this.data.name];
    },
    angle() {
      const { desiredAngle, aspectType } = this.data;
      const exactAngle = convertToDms(desiredAngle);

      if (aspectType === AspectType.EXACT) {
        return `(${exactAngle})`;
      } else if (aspectType === AspectType.CONVERGING) {
        return `>${exactAngle}<`;
      } else {
        return `<${exactAngle}>`;
      }
    },
    content() {
      const { fromSubject, toSubject } = this.data;

      const fromSubjectTranslated = this.getAspectableName(fromSubject);
      const toSubjectTranslated = this.getAspectableName(toSubject);

      return `${i18n.t(fromSubjectTranslated)} - ${i18n.t(toSubjectTranslated)} ${this.angle}`;
    },
  },
  methods: {
    getAspectableName({ subjectType, name }) {
      const translationMap = {
        [AspectableType.PLANET]: (name) => PLANET_NAMES_TRANSLATION[name],
        [AspectableType.CUSP]: (name) => `${CUSP_NUMERICAL_NAMES[name]} ${i18n.t('house')}`,
      };

      return translationMap[subjectType](name);
    },
  },
};
</script>

<style scoped></style>
