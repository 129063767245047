<template>
  <v-tooltip bottom content-class="tooltip-white">
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
        <slot />
        <v-progress-linear :value="value" :color="color" background-color="#F2F2F2" height="3" />
      </div>
    </template>
    <div class="tooltip-content">
      <span class="tooltip-content__title">Тестовый период</span>
      <span class="tooltip-content__info">Осталось: {{ tariffExpireDays }} из 14 дней</span>
      <v-progress-linear :value="value" :color="color" background-color="#F2F2F2" height="3" />
    </div>
  </v-tooltip>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TrialInfoTooltip',
  computed: {
    ...mapGetters('UserModule', ['tariffExpireDays']),
    value() {
      return (this.tariffExpireDays / 14) * 100;
    },
    color() {
      switch (true) {
        case this.tariffExpireDays === 1:
          return '#B00020';
        case this.tariffExpireDays > 1 && this.tariffExpireDays <= 7:
          return '#F2C94C';
        default:
          return '#167EA2';
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.tooltip-content
  display: flex
  flex-direction: column
  align-items: center
  width: base-unit(200)
  padding: base-unit(10) base-unit(20) base-unit(20) base-unit(20)
  background-color: #ffffff
  +astro-box-shadow
  border-radius: base-unit(4)
  +medium-devices
    position: absolute

  &__title
    font-size: base-unit(16)
    color: grey-color('500')

  &__info
    font-size: base-unit(12)
    color: grey-color('400')

.v-progress-linear
  border-radius: base-unit(4)
</style>
