<template>
  <TableTemplate :title="$t('options.tableCoords')" transition-speed="0.5s">
    <check-box @change="toggleSpeedVisibility">
      {{ $t('showSpeed') }}
    </check-box>
    <div class="table-wrapper" v-if="tables">
      <table
        v-for="table in tables"
        :key="table.id"
        class="coords-table"
        :class="{ 'ph-loading--table': showSkeleton }"
      >
        <caption v-if="table.name" class="text-title" :class="{ 'ph-loading--title': showSkeleton }">
          {{
            $t(table.name)
          }}
        </caption>
        <tbody>
          <tr v-for="tableRow in table.rows" :key="tableRow.id">
            <td>
              <v-tooltip right content-class="tooltip-white">
                <template v-slot:activator="{ on, attrs }">
                  <div
                    class="img-center"
                    v-bind="attrs"
                    v-on="on"
                    @mouseenter="highlightElements([tableRow.model])"
                    @mouseleave="removeHighlight([tableRow.model])"
                  >
                    <img
                      v-if="tableRow.signImg"
                      :src="tableRow.signImg"
                      :alt="tableRow.signName"
                      :width="imgSize"
                      :height="imgSize"
                    />
                    <span v-else>
                      {{ $t(tableRow.signName) }}
                    </span>
                  </div>
                </template>
                <span>{{ $t(tableRow.signHint) }}</span>
              </v-tooltip>
            </td>
            <td class="text-retrograde">
              <span v-if="tableRow.isRetrograde"> R </span>
            </td>
            <td>
              <span>{{ tableRow.degree }}</span>
            </td>
            <td>
              <v-tooltip right content-class="tooltip-white">
                <template v-slot:activator="{ on, attrs }">
                  <div class="img-center" v-bind="attrs" v-on="on">
                    <img :src="tableRow.zodiac" :alt="tableRow.zodiacName" :width="imgSize" :height="imgSize" />
                  </div>
                </template>
                <span>{{ $t(tableRow.zodiacName) }}</span>
              </v-tooltip>
            </td>
            <td>
              <template v-if="tableRow.speed && speedShown">
                <v-tooltip right content-class="tooltip-white">
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on" class="percent"> {{ tableRow.relativeSpeed }} &#37; </span>
                  </template>
                  <span>{{ $t('speed') }} {{ tableRow.speed }}</span>
                </v-tooltip>
              </template>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </TableTemplate>
</template>

<script>
import { Planet, Zodiac } from 'astro-chart';
import { mapGetters, mapState } from 'vuex';

import TableTemplate from './TableTemplate';
import { PLANET_NAMES_TRANSLATION, ZODIAC_NAMES_TRANSLATION } from '@/types';
import { convertToDms } from '@/utils/degrees';
import HighlightMixin from '@/elements/pages/chart/highlight-mixin';
import CheckBox from '@/elements/components/check-box';
import { i18n } from '@/plugins/i18n';

export default {
  name: 'CoordsTable',
  components: { TableTemplate, CheckBox },
  data() {
    return {
      imgSize: 16,
      speedShown: false,
    };
  },
  mixins: [HighlightMixin],
  computed: {
    ...mapState('ChartModule', ['firstPersonData', 'secondPersonData']),
    ...mapGetters('ChartModule', ['showSkeleton', 'isDoubleChart']),
    tables() {
      if (!this.firstPersonData.planetModels) {
        return null;
      }

      const isNatal = !this.isDoubleChart;
      if (isNatal) {
        return [
          this.transformPlanets(this.firstPersonData.planetModels),
          this.transformCusps(this.firstPersonData.cuspModels),
        ].map((rows, index) => ({
          id: index,
          rows,
        }));
      } else {
        return [this.firstPersonData, this.secondPersonData]
          .filter(({ planetModels, cuspModels }) => planetModels && cuspModels)
          .map(({ planetModels, cuspModels, name }, index) => ({
            name,
            id: index,
            rows: [...this.transformPlanets(planetModels), ...this.transformCusps(cuspModels)],
          }));
      }
    },
    planetImages() {
      return Object.values(Planet).reduce((acc, planet) => {
        acc[planet] = {
          name: planet,
          // eslint-disable-next-line no-undef
          path: require(`@/assets/planets/${planet}.svg`),
        };
        return acc;
      }, {});
    },
    zodiacImages() {
      return Object.values(Zodiac).reduce((acc, zodiac) => {
        acc[zodiac] = {
          name: zodiac,
          // eslint-disable-next-line no-undef
          path: require(`@/assets/zodiacs/${zodiac}.svg`),
        };
        return acc;
      }, {});
    },
  },
  methods: {
    transformPlanets(planets) {
      return Object.values(Planet).map((planetName) => {
        const planet = planets[planetName];
        const { name, zodiac, relativeAngle, retrograde, speed, relativeSpeed } = planet;

        return {
          id: name,
          model: planet,
          signImg: this.planetImages[name].path,
          signName: PLANET_NAMES_TRANSLATION[name],
          signHint: PLANET_NAMES_TRANSLATION[name],
          isRetrograde: retrograde,
          degree: convertToDms(relativeAngle),
          zodiac: this.zodiacImages[zodiac.name].path,
          zodiacName: ZODIAC_NAMES_TRANSLATION[zodiac.name],
          speed: convertToDms(speed),
          relativeSpeed,
        };
      });
    },
    transformCusps(cusps) {
      return Object.values(cusps).map((cusp) => {
        const { index, zodiac, relativeAngle } = cusp;

        return {
          id: cusp.name,
          model: cusp,
          signName: cusp.name,
          signHint: `${index + 1} ${i18n.t('house')}`,
          degree: convertToDms(relativeAngle),
          zodiac: this.zodiacImages[zodiac.name].path,
          zodiacName: ZODIAC_NAMES_TRANSLATION[zodiac.name],
        };
      });
    },
    toggleSpeedVisibility() {
      this.speedShown = !this.speedShown;
    },
  },
};
</script>

<style scoped lang="sass">
@import "src/style/application"

.table-wrapper
  display: flex
  justify-content: space-between

  +medium-devices
    justify-content: unset

  +extra-large-devices
    justify-content: space-between

.coords-table-block
  & .table-template__body
    transition-duration: 0.3s !important

.img-center
  display: flex
  align-items: center
  width: base-unit(16)

.coords-table
  &:not(:last-child)
    margin: 0

    +medium-devices
      margin-right: base-unit(60)

    +extra-large-devices
      margin: 0

.text
  &-title
    color: blue-color('primary')
    font-weight: 700
    font-size: base-unit(16)
    text-align: left
    margin-bottom: base-unit(10)

  &-sign
    font-weight: 400

  &-retrograde
    color: blue-color('primary')

table
  border-spacing: 0

tr
  text-align: left

  &:not(:first-child) > td
    border-top: thin solid grey-color('300')

td
  height: base-unit(32)
  padding: 0 base-unit(2)
  font-size: base-unit(15)

  +medium-devices
    font-size: $base-font-size
    padding: 0 base-unit(7)

  @media (min-width: #{$screen-exlg-min}) and (max-width:  #{base-unit(1600)})
    font-size: base-unit(15)
    &.text-retrograde
      padding: 0 base-unit(3)
    .percent
      white-space: nowrap

  @media (min-width: #{base-unit(364)}) and (max-width:  #{base-unit(400)})
    font-size: base-unit(13)
    &.text-retrograde
      padding: 0 base-unit(3)
    .percent
      white-space: nowrap

  @media (min-width: #{base-unit(350)}) and (max-width:  #{base-unit(363)})
    font-size: base-unit(12)
    &.text-retrograde
      padding: 0 base-unit(3)
    .percent
      white-space: nowrap

  @media (min-width: #{base-unit(320)}) and (max-width:  #{base-unit(349)})
      font-size: base-unit(10)
      &.text-retrograde
        padding: 0 base-unit(3)
      .percent
        white-space: nowrap
</style>
